.manage-airplane-types-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.manage-airplane-types-container h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #2c3e50;
    font-weight: 300;
}

.manage-airplane-types-container .airplane-types-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.manage-airplane-types-container .airplane-types-table th, 
.manage-airplane-types-container .airplane-types-table td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #e9ecef;
}

.manage-airplane-types-container .airplane-types-table th {
    background-color: #3498db;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.9rem;
}

.manage-airplane-types-container .airplane-types-table tr:last-child td {
    border-bottom: none;
}

.manage-airplane-types-container .airplane-types-table tr:hover {
    background-color: #f1f3f5;
}

.manage-airplane-types-container .airplane-types-table input {
    width: 100%;
    padding: 8px 12px;
    margin: 4px 0;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.manage-airplane-types-container .airplane-types-table input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.manage-airplane-types-container .airplane-types-table input[type="number"] {
    width: 70px;
}

.manage-airplane-types-container .class-inputs {
    display: flex;
    gap: 10px;
}

.manage-airplane-types-container .airplane-types-table button {
    padding: 8px 16px;
    margin: 4px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    width: calc(50% - 8px);
    display: inline-block;
    font-size: 0.85rem; /* Slightly smaller font size for buttons */
}

.manage-airplane-types-container .airplane-types-table button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.manage-airplane-types-container .airplane-types-table button:active {
    transform: translateY(1px);
}

.manage-airplane-types-container .airplane-types-table button.edit-btn {
    background-color: #3498db;
}

.manage-airplane-types-container .airplane-types-table button.delete-btn {
    background-color: #e74c3c;
}

.manage-airplane-types-container .airplane-types-table button.cancel-btn {
    background-color: #95a5a6; /* Gray color for cancel button */
}

.manage-airplane-types-container .add-new-type td {
    background-color: #f8f9fa;
}

.manage-airplane-types-container .add-new-type button {
    background-color: #2ecc71;
    width: 100%;
}

@media (max-width: 768px) {
    .manage-airplane-types-container {
        padding: 15px;
    }

    .manage-airplane-types-container .airplane-types-table th, 
    .manage-airplane-types-container .airplane-types-table td {
        padding: 10px;
    }

    .manage-airplane-types-container .airplane-types-table input,
    .manage-airplane-types-container .add-new-type input {
        font-size: 0.8rem;
    }

    .manage-airplane-types-container .airplane-types-table button,
    .manage-airplane-types-container .add-new-type button {
        padding: 6px 12px;
        font-size: 0.75rem;
    }
}

@media (max-width: 480px) {
    .manage-airplane-types-container h1 {
        font-size: 2rem;
    }

    .manage-airplane-types-container .airplane-types-table th, 
    .manage-airplane-types-container .airplane-types-table td {
        padding: 8px;
    }

    .manage-airplane-types-container .airplane-types-table input,
    .manage-airplane-types-container .add-new-type input {
        font-size: 0.75rem;
    }

    .manage-airplane-types-container .airplane-types-table button,
    .manage-airplane-types-container .add-new-type button {
        padding: 5px 10px;
        font-size: 0.7rem;
    }
}


.manage-airplane-types-container .search-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.manage-airplane-types-container .search-icon {
    color: #3498db;
    margin-right: 10px;
}

.manage-airplane-types-container .search-input {
    flex-grow: 1;
    border: none;
    font-size: 1rem;
    padding: 8px;
    outline: none;
}

.manage-airplane-types-container .search-input::placeholder {
    color: #95a5a6;
}

@media (max-width: 768px) {
    .manage-airplane-types-container .search-container {
        flex-direction: column;
        align-items: stretch;
    }

    .manage-airplane-types-container .search-icon {
        margin-right: 0;
        margin-bottom: 8px;
    }

    .manage-airplane-types-container .search-input {
        width: 100%;
    }
}