.header1 {
  direction: ltr !important;
  background-color: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header1 .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.header1 .header-left {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.header1 .header-right {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;
  position: relative;
}

.header1 .menu-toggle,
.header1 .profile-link,
.header1 .search-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #333;
  margin-left: 15px;
  cursor: pointer;
}

html[dir = "rtl"] .header1 .nav-menu {
  direction: rtl !important;
}

.header1 .nav-menu {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}

.header1 .nav-menu a,
.header1 .dropdown-menu a {
  color: #333;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1.1rem;
  font-weight: 500;
  position: relative;
  padding: 5px 0;
}

.header1 .nav-menu a:hover,
.header1 .nav-menu a.active,
.header1 .dropdown-menu a:hover,
.header1 .dropdown-menu a.active {
  color: #0000ff;
}

.header1 .nav-menu a::after,
.header1 .dropdown-menu a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #000;
  transition: width 0.3s ease;
  background: linear-gradient(to right, #0047AB, #00BFFF);
}

.header1 .nav-menu a:hover::after,
.header1 .nav-menu a.active::after,
.header1 .dropdown-menu a:hover::after,
.header1 .dropdown-menu a.active::after {
  width: 100%;
}

.header1 .logo-image {
  height: 40px;
  width: auto;
}

.header1 .dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  z-index: 1001;
  max-height: 80vh;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.header1 .dropdown-menu.active {
  opacity: 1;
  visibility: visible;
}

.header1 .dropdown-menu a {
  padding: 8px 15px;
  margin: 0;
  white-space: nowrap;
}

.logo-image {
  cursor: pointer;
}

@media (max-width: 768px) {
  .header1 .nav-menu {
    display: none;
  }

  .header1 .nav-menu.active {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .header1 .nav-menu a {
    margin: 10px 0;
  }

  .header1 .nav-menu a::after {
    bottom: -5px;
  }

  .header1 .dropdown-menu {
    width: 200px;
    right: -10px;
  }

  .header1 .dropdown-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
  }

  .header1 .dropdown-menu.active {  
    transform: translateX(0);
  }
}








