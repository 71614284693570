.manage-flights-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.manage-flights-container h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #2c3e50;
    font-weight: 300;
}

.manage-flights-container .flights-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.manage-flights-container .flights-table th, 
.manage-flights-container .flights-table td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #e9ecef;
}

.manage-flights-container .flights-table th {
    background-color: #3498db;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.9rem;
}

.manage-flights-container .flights-table tr:last-child td {
    border-bottom: none;
}

.manage-flights-container .flights-table tr:hover {
    background-color: #f1f3f5;
}

.manage-flights-container .flights-table input {
    width: 100%;
    padding: 8px 12px;
    margin: 4px 0;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.manage-flights-container .flights-table input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.manage-flights-container .flights-table input[type="date"], 
.manage-flights-container .flights-table input[type="time"],
.manage-flights-container .flights-table input[type="number"] {
    width: auto;
}

.manage-flights-container .seats-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 5px;
}

.manage-flights-container .seats-info input {
    width: 60px;
}

.manage-flights-container .btn {
    padding: 8px 16px;
    margin: 4px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    font-size: 0.85rem;
    width: 100%;
    display: block;
}

.manage-flights-container .btn:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.manage-flights-container .btn:active {
    transform: translateY(1px);
}

.manage-flights-container .edit-btn { background-color: #3498db; }
.manage-flights-container .save-btn { background-color: #2ecc71; }
.manage-flights-container .delete-btn { background-color: #e74c3c; }
.manage-flights-container .cancel-btn { background-color: #95a5a6; }

.manage-flights-container .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.manage-flights-container .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .manage-flights-container .flights-table {
        font-size: 0.9rem;
    }

    .manage-flights-container .flights-table th, 
    .manage-flights-container .flights-table td {
        padding: 10px;
    }

    .manage-flights-container .btn {
        padding: 6px 12px;
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .manage-flights-container h1 {
        font-size: 2rem;
    }

    .manage-flights-container .flights-table th, 
    .manage-flights-container .flights-table td {
        padding: 8px;
    }

    .manage-flights-container .btn {
        padding: 5px 10px;
        font-size: 0.75rem;
    }
}



.manage-flights-container .search-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.manage-flights-container .search-icon {
    color: #3498db;
    margin-right: 10px;
}

.manage-flights-container .search-input {
    flex-grow: 1;
    border: none;
    font-size: 1rem;
    padding: 8px;
    outline: none;
}

.manage-flights-container .search-input::placeholder {
    color: #95a5a6;
}

@media (max-width: 768px) {
    .manage-flights-container .search-container {
        flex-direction: column;
        align-items: stretch;
    }

    .manage-flights-container .search-icon {
        margin-right: 0;
        margin-bottom: 8px;
    }

    .manage-flights-container .search-input {
        width: 100%;
    }
}