
.manage-bookings-container {
  padding: 20px;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}

.manage-bookings-container .manage-bookings-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 15px;
}

.manage-bookings-container .flight-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.manage-bookings-container .flight-card:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.manage-bookings-container .flight-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #3498db;
  color: white;
}

.manage-bookings-container .flight-info h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.manage-bookings-container .flight-info p {
  margin: 5px 0;
  font-size: 0.9rem;
  opacity: 0.9;
}

.manage-bookings-container .flight-actions {
  display: flex;
  gap: 10px;
}

.manage-bookings-container .toggle-seat-map-btn,
.manage-bookings-container .toggle-bookings-btn,
.manage-bookings-container .toggle-passengers-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.manage-bookings-container .toggle-seat-map-btn {
  background-color: #2ecc71;
  color: white;
}

.manage-bookings-container .toggle-bookings-btn {
  background-color: #e74c3c;
  color: white;
}

.manage-bookings-container .toggle-passengers-btn {
  background-color: #9b59b6;
  color: white;
}

.manage-bookings-container .toggle-seat-map-btn:hover,
.manage-bookings-container .toggle-bookings-btn:hover,
.manage-bookings-container .toggle-passengers-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.manage-bookings-container .toggle-seat-map-btn:active,
.manage-bookings-container .toggle-bookings-btn:active,
.manage-bookings-container .toggle-passengers-btn:active {
  transform: translateY(0);
}

.manage-bookings-container .seat-map-container,
.manage-bookings-container .bookings-container,
.manage-bookings-container .passengers-container {
  padding: 20px;
}

.manage-bookings-container .booking-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.manage-bookings-container .booking-header {
  background-color: #34495e;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-bookings-container .booking-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.manage-bookings-container .booking-details {
  display: flex;
  padding: 20px;
}

.manage-bookings-container .booking-info {
  flex: 1;
  padding-right: 20px;
}

.manage-bookings-container .tickets-container {
  flex: 2;
}

.manage-bookings-container .ticket-card {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.manage-bookings-container .ticket-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-bookings-container .passenger-details ul {
  list-style-type: none;
  padding: 0;
}

.manage-bookings-container .passenger-details li {
  margin-bottom: 5px;
}

.manage-bookings-container .icon {
  margin-right: 8px;
  color: #3498db;
}

.manage-bookings-container .seat-map {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manage-bookings-container .seat-class-section {
  margin-bottom: 20px;
  text-align: center;
}

.manage-bookings-container .seat-grid {
  display: inline-block;
}

.manage-bookings-container .seat-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.manage-bookings-container .seat {
  width: 30px;
  height: 30px;
  margin: 0 3px;
  border: none;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #b0c4de;
  color: #333;
}

.manage-bookings-container .seat.reserved {
  background-color: #d3d3d3;
  color: #999;
  cursor: not-allowed;
}

.manage-bookings-container .aisle {
  width: 20px;
}

.manage-bookings-container .no-bookings,
.manage-bookings-container .no-flights {
  text-align: center;
  color: #777;
  font-style: italic;
}

.manage-bookings-container .loading {
  text-align: center;
  font-size: 1.2rem;
  color: #3498db;
  margin-top: 20px;
}

.manage-bookings-container .delete-booking-btn,
.manage-bookings-container .delete-ticket-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.manage-bookings-container .delete-booking-btn:hover,
.manage-bookings-container .delete-ticket-btn:hover {
  background-color: #ff1a1a;
}

.manage-bookings-container .passengers-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.manage-bookings-container .passengers-table th,
.manage-bookings-container .passengers-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.manage-bookings-container .passengers-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.manage-bookings-container .passengers-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.manage-bookings-container .passengers-table tr:hover {
  background-color: #f5f5f5;
}


.manage-bookings-container .search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
  gap: 15px;
  border-radius: 8px;

}

.manage-bookings-container .search-icon {
  margin-right: 10px;
  color: #3498db;
}

.manage-bookings-container .search-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.manage-bookings-container .search-input:focus {
  outline: none;
  border-color: #3498db;
}



.manage-bookings-container .global-search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.manage-bookings-container .global-search-input {
  flex-grow: 1;
  border: none;
  font-size: 16px;
  padding: 8px;
}

.manage-bookings-container .global-search-input:focus {
  outline: none;
}

.manage-bookings-container .search-icon {
  color: #3498db;
  margin-right: 10px;
  font-size: 20px;
}

.manage-bookings-container .qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.manage-bookings-container .qr-code-container p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.manage-bookings-container .qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

























.flight-ticket {
  background-color: #fff;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
}

.ticket-header {
  background-color: #3498db;
  color: #fff;
  padding: 15px;
  text-align: center;
}

.ticket-header h2 {
  margin: 0;
  font-size: 24px;
}

.ticket-type {
  font-size: 14px;
  margin-top: 5px;
}

.ticket-body {
  display: flex;
  padding: 20px;
}

.ticket-info {
  flex: 1;
}

.flight-details {
  margin-bottom: 20px;
}

.detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail .icon {
  margin-right: 10px;
  color: #3498db;
}

.route-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.from, .to {
  text-align: center;
}

.from h3, .to h3 {
  margin: 0;
  font-size: 24px;
  color: #3498db;
}

.plane-icon {
  font-size: 24px;
  color: #3498db;
}

.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  border-left: 1px dashed #e0e0e0;
}

.ticket-footer {
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #777;
}

.return-ticket {
  margin-top: 30px;
}



@media (max-width: 600px) {
  .ticket-body {
    flex-direction: column;
  }

  .qr-code {
    border-left: none;
    border-top: 1px dashed #e0e0e0;
    margin-top: 20px;
    padding-top: 20px;
    padding-left: 0;
  }
}







.manage-bookings-container .print-ticket-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #3ad3a0;  /* Green background */
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.manage-bookings-container .print-ticket-btn:hover {
  background-color: #059669;  /* Darker green on hover */
  transform: scale(1.01);  /* Slight scaling effect */
}

.manage-bookings-container .print-ticket-btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.5);  /* Green focus ring */
}

.manage-bookings-container .print-ticket-btn:active {
  background-color: #047857;  /* Even darker green on active */
}

.manage-bookings-container .print-ticket-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.manage-bookings-container .print-ticket-btn::before {
  content: "\1F5B6";  /* Unicode for a printer icon */
  margin-right: 0.5rem;
  font-size: 1rem;
}




