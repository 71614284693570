.saved-orders {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.saved-orders h2 {
    font-size: 2.25rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 2rem;
    text-align: center;
    border-bottom: 2px solid #e5e7eb;
    padding-bottom: 1rem;
}

.saved-orders .no-orders {
    text-align: center;
    color: #6b7280;
    font-size: 1.1rem;
}

.saved-orders .saved-order-item {
    background-color: #f9fafb;
    border-radius: 0.75rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;
    border: 1px solid #e5e7eb;
}

.saved-orders .saved-order-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.saved-orders .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.saved-orders .order-header h3 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #4f46e5;
}

.saved-orders .expiration {
    display: flex;
    align-items: center;
    color: #ef4444;
    font-weight: bold;
}

.saved-orders .clock-icon {
    margin-right: 0.5rem;
}

.saved-orders .order-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.saved-orders .order-details p {
    color: #4b5563;
    margin: 0;
}

.saved-orders .order-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.saved-orders .order-actions button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
}

.saved-orders .resume-btn {
    background-color: #4f46e5;
    color: white;
}

.saved-orders .resume-btn:hover {
    background-color: #4338ca;
    transform: scale(1.05);
}

.saved-orders .delete-btn {
    background-color: #ef4444;
    color: white;
}

.saved-orders .delete-btn:hover {
    background-color: #dc2626;
    transform: scale(1.05);
}