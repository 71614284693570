.seat-selection-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px 10px;
  background: linear-gradient(135deg, #e0e6ed 0%, #f2f6f9 100%);
  border-radius: 150px 150px 20px 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.seat-selection-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 40px;
  background: linear-gradient(135deg, #3498db, #2980b9);
  border-radius: 0 0 50% 50%;
  z-index: 1;
}

.seat-selection-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 20px;
  background: linear-gradient(135deg, #3498db, #2980b9);
  border-radius: 50% 50% 0 0;
  z-index: 1;
}

.seat-selection-instructions-text {
  text-align: center;
  margin-bottom: 20px;
  color: #34495e;
  position: relative;
  z-index: 2;
}

.seat-selection-instructions-text h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.seat-selection-seat-class-section {
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.seat-selection-seat-class-section h3 {
  text-align: center;
  margin-bottom: 10px;
  color: #2c3e50;
  font-size: 18px;
}

.seat-selection-seat-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seat-selection-seat-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.seat-selection-seat {
  width: 50px;
  height: 50px;
  margin: 0 2px;
  border: none;
  background-color: #ecf0f1;
  color: #34495e;
  font-weight: bold;
  font-size: 10px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: visible;
}

.seat-selection-seat::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #bdc3c7;
  border-radius: 0 0 5px 5px;
}

.seat-selection-seat:hover {
  transform: translateY(-3px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.seat-selection-seat.selected {
  background-color: #2ecc71;
  color: white;
}

.seat-selection-seat.reserved {
  background-color: #a69e9d;
  color: white;
  cursor: not-allowed;
}

.seat-selection-seat.window {
  background-color: #3498db;
  color: white;
}

.seat-selection-seat.aisle {
  background-color: #f39c12;
  color: white;
}

.seat-selection-aisle {
  width: 10px;
}

/* .seat-selection-footer {
  text-align: center;
  margin-top: 20px;
  position: relative;
  z-index: 2;
} */

/* .seat-selection-confirm-seats-button {
  padding: 10px 20px;
  font-size: 16px;
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(52, 152, 219, 0.3);
}

.seat-selection-confirm-seats-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(52, 152, 219, 0.4);
} */

.seat-selection-footer-left {
  position: fixed;
  left: 3%;
  top: 90%;
  transform: translateY(-50%);
  z-index: 1000;
}

.seat-selection-confirm-seats-button {
  padding: 40px 70px;
  font-size: 16px;
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: white;
  border: none;
  border-radius: 25px;
  font: 1.3em sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(52, 152, 219, 0.3);
  white-space: nowrap;
}

.seat-selection-confirm-seats-button:hover {
  transform: translateY(-2px);
  background: linear-gradient(135deg, #3498db, #1d6798);
  box-shadow: 0 5px 15px rgba(52, 152, 219, 0.4);
}


.seat-selection-error-message {
  color: #e74c3c;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .seat-selection-container {
    padding: 10px 5px;
    border-radius: 75px 75px 10px 10px;
  }

  .seat-selection-seat {
    width: 25px;
    height: 25px;
    font-size: 8px;
  }

  .seat-selection-aisle {
    width: 8px;
  }
}