.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../assets/img/background.jpg');
  background-size: cover;
  background-position: center;
  font-family: 'Arial', sans-serif;
}

.auth-container {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 40px;
  padding-bottom: 0px;
  min-height: 70vh;

  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.auth-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
}

.auth-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.auth-toggle button {
  background: none;
  border: none;
  color: #888;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-toggle button.active {
  color: #4a90e2;
  border-bottom: 2px solid #4a90e2;
}

.google-auth-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #333;
}

.google-auth-btn:hover {
  background-color: #f5f5f5;
}

.google-auth-btn img {
  width: 20px;
  margin-right: 10px;
}

.auth-page .separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #888;
  margin: 20px 0;
}

.auth-page .separator::before,
.auth-page .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.auth-page .separator span {
  padding: 0 10px;
}

.auth-page form {
  display: flex;
  flex-direction: column;
}

.auth-page input {
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.auth-page button {
  padding: 12px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-page button:hover {
  background-color: #3a7bc8;
}

.forgot-password {
  text-align: right;
  color: #4a90e2;
  cursor: pointer;
  margin-top: 10px;
}

.switch-auth {
  text-align: center;
  margin-top: 20px;
  color: #888;
}

.switch-auth span {
  color: #4a90e2;
  cursor: pointer;
}


.reset-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.reset-btn, .back-btn {
  width: 48%;
}

.reset-btn {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-btn:hover {
  background-color: #3a7bc8;
}

.back-btn {
  background-color: #ddd;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: #ccc;
}

.password-wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.password-wrapper input {
  flex: 1;
  min-width: 0;
}

.auth-container input {
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included within the width */
}
