

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto+Mono:wght@400;700&display=swap');

.coupon-management {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 3%;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;

}

.coupon-management h2,
.coupon-management h3 {
    color: #2c3e50;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}

.coupon-management .coupon-code,
.coupon-management .coupon-amount,
.coupon-management .used-details span,
.coupon-management .coupon-date {
    font-family: 'Roboto Mono', monospace;
}


  .coupon-management .search-bar {
    margin-bottom: 1rem;
  }
  
  .coupon-management .search-bar input {
    width: 30%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    font-size: 1rem;
  }  
  
  .coupon-management .coupon-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .coupon-management .coupon-form .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .coupon-management .coupon-form label {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .coupon-management .coupon-form input[type='number'] {
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
  
  .coupon-management .create-coupon-btn,
  .coupon-management .cancel-edit-btn {
    padding: 0.75rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .coupon-management .create-coupon-btn {
    background: linear-gradient(to right, #7c3aed, #4f46e5);
  }
  
  .coupon-management .cancel-edit-btn {
    background-color: #6b7280;
    margin-top: 0.5rem;
  }
  
  .coupon-management .create-coupon-btn:hover,
  .coupon-management .cancel-edit-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .coupon-management .error-message {
    color: #ef4444;
    margin-top: 0.5rem;
  }
  
  .coupon-management .coupons-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .coupon-management .coupons-list {
    flex: 1;
    min-width: 300px;
  }
  
  .coupon-management .coupons-list h3 {
    font-size: 20px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .coupon-management .coupons-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .coupon-management .coupon-item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #f3f4f6;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .coupon-management .coupon-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .coupon-management .coupon-info {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }
  
  .coupon-management .coupon-info span {
    font-size: 1rem;
  }
  
  .coupon-management .used-details {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #e5e7eb;
    border-radius: 0.25rem;
  }
  
  .coupon-management .used-details span {
    display: block;
    font-size: 0.9rem;
    color: #374151;
    margin-bottom: 0.5rem;
  }
  
  .coupon-management .coupon-actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.75rem;
  }
  
  .coupon-management .edit-btn,
  .coupon-management .delete-btn {
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    transition: background-color 0.3s;
  }
  
  .coupon-management .edit-btn {
    background-color: #3b82f6;
    color: white;
  }
  
  .coupon-management .edit-btn:disabled {
    background-color: #777777;
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .coupon-management .delete-btn {
    background-color: #ef4444;
    color: white;
  }
  
  .coupon-management .edit-btn:hover:not(:disabled) {
    background-color: #2563eb;
  }
  
  .coupon-management .delete-btn:hover {
    background-color: #dc2626;
  }
  
  .coupon-management .icons {
    display: flex;
    gap: 10px;
  }
  
  .coupon-management .icon {
    font-size: 22px;
    cursor: pointer;
  }
  
  .coupon-management .used-coupon {
    color: rgb(139, 96, 96);
  }
  
  .coupon-management .not-used-coupon {
    color: green;
  }
  
  .coupon-management .copy-icon:hover {
    color: #4b5563;
  }
  
  .coupon-management .coupon-item:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .coupon-management .coupons-columns {
      flex-direction: column;
    }
  }
  