.home-page {
  --home-primary-color: #1a1a1a;
  --home-secondary-color: #d4af37;
  --home-accent-color: #835ecc;
  --home-text-color: #333;
  --home-background-color: #f8f9fa;
  --home-white: #ffffff;

  font-family: 'Heebo', sans-serif;
  background-color: var(--home-background-color);
  color: var(--home-text-color);
}

.home-page .home-app {
  max-width: 100%;
  overflow-x: hidden;
}

/* Header and Navigation */
.home-page .home-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}

.home-page .home-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}

.home-page .home-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--home-primary-color);
}

.home-page .home-nav-links {
  display: flex;
  gap: 2rem;
}

.home-page .home-nav-links a {
  color: var(--home-primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.home-page .home-nav-links a:hover {
  color: var(--home-secondary-color);
}

/* Hero Section */
.home-page .home-hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.home-page .home-hero-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  will-change: transform;
}

.home-page .home-hero-content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--home-white);
  padding: 0 2rem;
  background: rgba(0, 0, 0, 0.3);
}

.home-page .home-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.home-page .home-hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.home-page .home-search-bar {
  direction: ltr !important;
  display: flex;
  max-width: 600px;
  width: 100%;
  overflow: hidden; /* Prevents content from growing outside */
}

.home-page .home-search-bar input {
  flex-grow: 1;
  font-size: 1rem;
  border: 2px solid transparent; /* Default border, but invisible */
  border-radius: 4px 0 0 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for focus effect */
  outline: none; /* Remove default focus outline */
}

.home-page .home-search-bar input:focus {
  border-color: #ffee53; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(255, 237, 192, 0.5); /* Optional: Add shadow for better focus effect */
}

html[dir="rtl"] .home-page .home-search-bar input,
html[dir="rtl"] .home-page .home-search-button {
  direction: rtl !important;
}

.home-page .home-search-button {
  padding: 1rem 2rem;
  font-size: 1rem;
  background-color: var(--home-secondary-color);
  color: var(--home-primary-color);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-page .home-search-button:hover {
  background-color: var(--home-accent-color);
  color: var(--home-white);
}


/* Stats Section */
.home-page .home-stats {
  background-color: var(--home-primary-color);
  color: var(--home-white);
  padding: 4rem 0;
}

.home-page .home-stats-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.home-page .home-stat-item {
  text-align: center;
}

.home-page .home-stat-icon {
  font-size: 3rem;
  color: var(--home-secondary-color);
  margin-bottom: 1rem;
}

.home-page .home-stat-item h3 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

/* Destinations Section */
.home-page .home-destinations,
.home-page .home-services {
  padding: 100px 50px;
  background-color: var(--home-white);
}

.home-page .home-destinations h2,
.home-page .home-services h2 {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  color: var(--home-primary-color);
  margin-bottom: 50px;
}

.home-page .home-destination-grid,
.home-page .home-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.home-page .home-destination-card,
.home-page .home-service-card {
  background-color: var(--home-white);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-page .home-destination-card:hover,
.home-page .home-service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0,0,0,0.2);
}

.home-page .home-destination-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.home-page .home-destination-card h3,
.home-page .home-service-card h3 {
  padding: 20px;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  color: var(--home-primary-color);
}

/* Services Section */
.home-page .home-service-card {
  text-align: center;
  padding: 30px;
}

.home-page .home-service-icon {
  font-size: 3rem;
  color: var(--home-secondary-color);
  margin-bottom: 20px;
}

/* Testimonials Section */
.home-page .home-testimonials {
  background-color: var(--home-background-color);
  padding: 4rem 0;
}

.home-page .home-testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.home-page .home-testimonial-card {
  background-color: var(--home-white);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-page .home-testimonial-author {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.home-page .home-testimonial-author img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.home-page .home-testimonial-rating {
  color: var(--home-secondary-color);
}

/* Call to Action Section */
.home-page .home-cta {
  background-color: var(--home-accent-color);
  color: var(--home-white);
  padding: 4rem 0;
  text-align: center;
}

.home-page .home-cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.home-page .home-cta-button {
  background-color: var(--home-white);
  color: var(--home-accent-color);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 2rem;
}

.home-page .home-cta-button:hover {
  background-color: var(--home-secondary-color);
  color: var(--home-primary-color);
  transform: translateY(-3px);
}

/* Footer */
.home-page .home-footer {
  background-color: var(--home-primary-color);
  color: var(--home-white);
  padding: 50px;
}

.home-page .home-footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.home-page .home-footer-section {
  margin-bottom: 30px;
}

.home-page .home-footer-section h3 {
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: var(--home-secondary-color);
}

.home-page .home-footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255,255,255,0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-page .home-hero-content h1 {
    font-size: 2.5rem;
  }

  .home-page .home-search-bar {
    flex-direction: column;
    align-items: center;
  }

  .home-page .home-search-bar input,
  .home-page .home-search-button {
    width: 100%;
    max-width: 300px;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .home-page .home-destinations,
  .home-page .home-services {
    padding: 50px 20px;
  }

  .home-page .home-stats-container,
  .home-page .home-testimonial-grid {
    flex-direction: column;
    align-items: center;
  }

  .home-page .home-stat-item,
  .home-page .home-testimonial-card {
    margin-bottom: 2rem;
  }

  .home-page .home-footer-content {
    flex-direction: column;
  }
}

.home-page .jojo {
  text-align: center;
}

.home-page .logo-image1 {
  width: 20%;
}

/* Gallery Section */
.home-page .home-gallery {
  padding: 4rem 0;
  background-color: var(--home-background-color);
}

.home-page .home-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.home-page .home-gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Partners Section */
.home-page .home-partners {
  padding: 4rem 0;
  background-color: var(--home-white);
}

.home-page .home-partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.home-page .home-partner-item {
  text-align: center;
}

.home-page .home-partner-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

/* Offices Section */
.home-page .home-offices {
  padding: 4rem 0;
  background-color: var(--home-background-color);
}

.home-page .home-offices-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.home-page .home-office-card {
  background-color: var(--home-white);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-page .home-office-info {
  padding: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .home-page .home-gallery-grid,
  .home-page .home-partners-grid,
  .home-page .home-offices-grid {
    grid-template-columns: 1fr;
  }
}

.home-page .destination-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.home-page .destination-modal-content {
  background-color: var(--home-white);
  padding: 2rem;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-page .close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--home-primary-color);
}

.home-page .destination-modal-content h2 {
  font-family: 'Playfair Display', serif;
  color: var(--home-primary-color);
  margin-bottom: 1rem;
}

.home-page .destination-modal-content img {
  width: 100%;
  height: 20vh;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.home-page .destination-info h3 {
  font-family: 'Playfair Display', serif;
  color: var(--home-secondary-color);
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.home-page .destination-info ul {
  list-style-type: none;
  padding-left: 0;
}

.home-page .destination-info li {
  margin-bottom: 0.5rem;
}

.home-page .destination-info a {
  color: var(--home-accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.home-page .destination-info a:hover {
  color: var(--home-secondary-color);
}

.home-page .destination-info p {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .home-page .destination-modal-content {
    max-width: 95%;
    max-height: 95%;
    padding: 1rem;
  }
}

.home-page .developer-info {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.home-page .developer-info a {
  color: var(--home-white);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.3s ease;
  color: #c9bbe6;

}

.home-page .developer-info a:hover {
  color: var(--home-secondary-color);
}

@media (max-width: 768px) {
  .home-page .home-footer-developers {
    flex-direction: column;
    align-items: center;
  }
}

.home-page .home-social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.home-page .home-social-icons a {
  color: var(--home-white);
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.home-page .home-social-icons a:hover {
  color: var(--home-secondary-color);
}

@media (max-width: 768px) {
  .home-page .home-social-icons {
    justify-content: center;
  }
}