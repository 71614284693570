.admin-chat-rooms {
  font-family: 'Arial', sans-serif;
  display: flex;
  height: 87vh;
  background-color: #f0f4f8;
  color: #f8f8f8;
  margin-top: 3%;
}

.admin-chat-rooms-list {
  width: 300px;
  background-color: #ffffff;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
}

.admin-chat-rooms-list h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2c3e50;
}

.admin-chat-rooms-room {
  padding: 15px;
  margin-bottom: 10px;
  background-color: #4a90e2;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.admin-chat-rooms-room:hover, .admin-chat-rooms-room.selected {
  background-color: #5bb721;
  color: #ffffff;
}

.admin-chat-rooms-room h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.admin-chat-rooms-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #ffffff;
  overflow: hidden;
  height: 80vh;
}

.admin-chat-rooms-details h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2c3e50;
  padding: 10px;
  background-color: #f0f4f8;
  border-radius: 8px;
}

.admin-chat-rooms-messages {
  direction: ltr !important;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-height: 70vh;
}

.admin-chat-rooms-message {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 18px;
  margin-bottom: 15px;
  line-height: 1.4;
  font-size: 16px;
}

.admin-chat-rooms .admin-chat-rooms-message.agent {
  background-color: #4a90e2;
  color: #ffffff;
  align-self: flex-end;
  margin-left: auto;
}

.admin-chat-rooms-message.user {
  background-color: #e9ecef;
  color: #333;
  align-self: flex-start;
}

.admin-chat-rooms-message-input {
  display: flex;
  gap: 10px;
}

.admin-chat-rooms-message-input input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
}

.admin-chat-rooms-message-input button {
  padding: 12px 24px;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: bold;
}

.admin-chat-rooms-message-input button:hover {
  background-color: #3a78c2;
}

.admin-chat-rooms-unread-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff4136;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}

.admin-chat-rooms-typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.admin-chat-rooms-typing-indicator span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: typing 1.4s infinite both;
}

.admin-chat-rooms-typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.admin-chat-rooms-typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
}

.admin-chat-rooms-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #4a90e2;
}