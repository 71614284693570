
.passenger-details-page {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f5f7fa;
  --card-background: #ffffff;
  --text-color: #333333;
  --border-color: #e0e0e0;
  --input-background: #f9f9f9;
  --input-border: #d1d1d1;
  --button-hover: #2980b9;
}

.passenger-details-page {
  background-color: var(--background-color);
  min-height: 100vh;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
}

.passenger-details-container {
  max-width: 1200px;
  margin: 0 auto;
}

.passenger-details-page .page-title {
  color: var(--primary-color);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.passenger-details-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.passenger-details-page .passenger-card {
  background-color: var(--card-background);
  border-radius: 10px;
  max-width: 1071px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: all 0.3s ease;
}

.passenger-details-page .passenger-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.passenger-details-page .passenger-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.passenger-details-page .passenger-icon {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-right: 1rem;
}

.passenger-details-page .passenger-title {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0;
}

.passenger-details-page .class-type {
  margin-left: auto;
  background-color: var(--secondary-color);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.passenger-details-page .passenger-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.passenger-details-page .round-trip-indicator {
  font: 1.2em sans-serif;
}

.passenger-details-page .form-group {
  display: flex;
  flex-direction: column;
}

.passenger-details-page .form-group label {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.passenger-details-page .form-group .icon {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.passenger-details-page .form-group input,
.passenger-details-page .form-group select {
  padding: 0.75rem;
  border: 1px solid var(--input-border);
  border-radius: 5px;
  font-size: 1rem;
  background-color: var(--input-background);
  transition: border-color 0.3s ease;
}

.passenger-details-page .form-group input:focus,
.passenger-details-page .form-group select:focus {
  outline: none;
  border-color: var(--primary-color);
}

.passenger-details-page .israeli-id {
  grid-column: 1 / -1;
  margin-top: 1rem;
}

.passenger-details-page .proceed-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  margin-top: 2rem;
}

.passenger-details-page .proceed-button:hover {
  background-color: var(--button-hover);
}

.passenger-details-page .nationality-id-group {
  display: flex;
  gap: 1.5rem;
}

.passenger-details-page .nationality-field,
.passenger-details-page .israeli-id-field {
  flex: 1;
}

/* .israeli-id-field {
  width: 207%;
} */

.nationality-field select{
  width: 100%;
}

[dir="rtl"] .passenger-details-page .passenger-icon {
  margin-right: 0;
  margin-left: 1rem;
}

[dir="rtl"] .passenger-details-page .class-type {
  margin-left: 0;
  margin-right: auto;
}

[dir="rtl"] .passenger-details-page .form-group .icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

@media (max-width: 768px) {
  .passenger-details-page {
    padding: 1rem;
  }

  .passenger-details-page .passenger-card {
    padding: 1.5rem;
  }

  .passenger-details-page .passenger-details-grid {
    grid-template-columns: 1fr;
  }
}
