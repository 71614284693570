
.flight-booking-page {
	direction: ltr !important;
	font-family: 'Poppins', sans-serif;
}

.flight-booking-page .flight-booking {
	display: flex;
	min-height: 100vh;
	padding: 20px;
	background: linear-gradient(135deg, #74EBD5, #9FACE6);
}

.flight-booking-page .world-map {
	flex: 1;
	position: relative;
	overflow: hidden;
	margin-left: -20px;
}

.flight-booking-page .map {
	width: 100%;
	height: 100%;
	object-fit: cover;
	filter: saturate(1.2) contrast(1.1);
}

.flight-booking-page .map-pin {
	position: absolute;
	width: 30px;
	height: 30px;
	transform: translate(-50%, -50%);
}

.flight-booking-page .pin {
	width: 40px;
	height: 40px;
	border-radius: 50% 50% 50% 0;
	background: #ff7675;
	position: absolute;
	transform: rotate(-45deg);
	left: 50%;
	top: -20%;
	margin: -20px 0 0 -20px;
	animation: bounce 1s infinite alternate;
}

.flight-booking-page .pin::after {
	content: '';
	width: 14px;
	height: 14px;
	margin: 13px 0 0 13px;
	background: #000;
	position: absolute;
	border-radius: 50%;
}

.flight-booking-page .pulse {
	background: rgba(231, 76, 60, 0.2);
	border-radius: 50%;
	height: 14px;
	width: 14px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -7px 0 0 -7px;
	transform: rotateX(55deg);
	z-index: -2;
}

.flight-booking-page .pulse:after {
	content: "";
	border-radius: 50%;
	height: 40px;
	width: 40px;
	position: absolute;
	margin: -13px 0 0 -13px;
	animation: pulsate 1s ease-out infinite;
	opacity: 0;
	box-shadow: 0 0 1px 2px #ff7675;
}

@keyframes pulsate {
	0% { transform: scale(0.1, 0.1); opacity: 0; }
	50% { opacity: 1; }
	100% { transform: scale(1.2, 1.2); opacity: 0; }
}

@keyframes bounce {
	0% { transform: translateY(0) rotate(-45deg); }
	100% { transform: translateY(-10px) rotate(-45deg); }
}

.flight-booking-page .flight-paths {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.flight-booking-page .flight-paths svg {
	width: 100%;
	height: 100%;
}

.flight-booking-page .flight-paths path {
	fill: none;
	stroke-width: 2;
	stroke-dasharray: 8;
	animation: dash 30s linear infinite;
	filter: drop-shadow(0 0 2px #6C5CE7);
}

.flight-booking-page .flight-paths path:nth-child(1) { stroke: #FF7675; }
.flight-booking-page .flight-paths path:nth-child(2) { stroke: #74B9FF; }
.flight-booking-page .flight-paths path:nth-child(3) { stroke: #55EFC4; }
.flight-booking-page .flight-paths path:nth-child(4) { stroke: #FDCB6E; }

@keyframes dash {
	to { stroke-dashoffset: -1000; }
}

.flight-booking-page .airplane {
	position: absolute;
	font-size: 36px;
	animation: fly 15s linear infinite, bob 3s ease-in-out infinite;
	z-index: 10;
	filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));
}

@keyframes fly {
	0% { left: -5%; top: 40%; }
	100% { left: 105%; top: 40%; }
}

@keyframes bob {
	0%, 100% { transform: translateY(0); }
	50% { transform: translateY(-15px); }
}

.flight-booking-page .booking-panel {
	flex: 0 0 450px;
	background: #FFFFFF;
	padding: 40px;
	border-radius: 20px;
	box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	max-height: 90vh;
	position: relative;
	border-radius: 20px;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
}

html[dir="rtl"] .flight-booking-page .booking-panel {
	direction: rtl;
}

html[dir="rtl"] .flight-booking-page .form-group label {
	right: 15px;
	left: auto;
}

html[dir="rtl"] .flight-booking-page select {
	background-position: left 20px center;
	padding-right: 20px;
	padding-left: 40px;
}

.flight-booking-page input[type="date"] {
	width: 100%;
	box-sizing: border-box;
}

.flight-booking-page .booking-title {
	color: #6C5CE7;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 40px;
	font-size: 28px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: relative;
}

.flight-booking-page .booking-title:after {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 50%;
	width: 100px;
	height: 4px;
	background: linear-gradient(to right, #6C5CE7, #00B894);
	transform: translateX(-50%);
	border-radius: 2px;
}

.flight-booking-page .booking-form {
	display: flex;
	flex-direction: column;
	gap: 25px;
	flex-grow: 1;
	max-width: 450px;
}

.flight-booking-page .form-group {
	position: relative;
	width: 100%;
}

.flight-booking-page .form-group label {
	position: absolute;
	top: -10px;
	left: 15px;
	background: #FFFFFF;
	padding: 0 5px;
	font-size: 12px;
	color: #6C5CE7;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	transition: all 0.3s ease;
	z-index: 1;
}

.flight-booking-page select,
.flight-booking-page input {
	width: 100%;
	padding: 15px 20px;
	border: 2px solid #3498db;
	border-radius: 30px;
	font-size: 16px;
	transition: all 0.3s ease;
	background-color: #FFFFFF;
	color: #2D3436;
	appearance: none;
}

.flight-booking-page input[type="date"] {
	padding-right: 30px;
}

.flight-booking-page input[type="date"]::-webkit-calendar-picker-indicator {
	color: #3498db;
	font-size: 16px;
	padding: 0 10px;
}

.flight-booking-page select {
	background-image: url('data:image/svg+xml;utf8,<svg fill="%233498db" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
	background-repeat: no-repeat;
	background-position: right 20px center;
	padding-right: 40px;
}

.flight-booking-page select:focus,
.flight-booking-page input:focus {
	outline: none;
	border-color: #00B894;
	box-shadow: 0 0 0 3px rgba(108, 92, 231, 0.1);
}

.flight-booking-page .form-group:hover label {
	color: #00B894;
}

.flight-booking-page .form-row {
	display: flex;
	gap: 20px;
}

.flight-booking-page .submit-btn {
	background: linear-gradient(45deg, #6C5CE7, #00B894);
	color: white;
	border: none;
	padding: 18px;
	border-radius: 30px;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s ease;
	text-transform: uppercase;
	letter-spacing: 1px;
	position: relative;
	overflow: hidden;
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.flight-booking-page .submit-btn:before {
	content: '';
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent);
	transition: all 0.5s;
}

.flight-booking-page .submit-btn:hover:before {
	left: 100%;
}

.flight-booking-page .submit-btn:hover {
	transform: translateY(-3px);
	box-shadow: 0 8px 25px rgba(108, 92, 231, 0.4);
}

.flight-booking-page .modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.flight-booking-page .modal-content {
	background: #FFFFFF;
	padding: 40px;
	border-radius: 20px;
	text-align: center;
	animation: modalAppear 0.5s ease;
}

@keyframes modalAppear {
	from { opacity: 0; transform: translateY(-50px); }
	to { opacity: 1; transform: translateY(0); }
}

.flight-booking-page .globe-loader {
	width: 100px;
	height: 100px;
	border: 3px solid #F0F0F0;
	border-top: 3px solid #6C5CE7;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	margin: 0 auto 20px;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

/* New styles for the flight type toggle */
/* .flight-booking-page .flight-type-toggle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 20px;
} */

.flight-booking-page .flight-type-toggle {
	margin-bottom: 10px;
}
  
.flight-booking-page .radio-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f0f4f8;
	border-radius: 30px;
	padding: 5px;
	width: 100%;
	max-width: 440px;
}
  
.flight-booking-page .radio-group label {
	flex: 1;
	text-align: center;
	padding: 10px;
	cursor: pointer;
	border-radius: 25px;
	transition: background-color 0.3s, color 0.3s;
}
  
.flight-booking-page .radio-group input[type="radio"] {
	display: none;
}
  
.flight-booking-page .radio-group label.active {
	background: linear-gradient(45deg, #6C5CE7, #00B894);
	color: white;
	font-weight: bold;
}
  
.flight-booking-page .radio-group label:hover {
	background-color: #e0e0e0;
}
  
.flight-booking-page .radio-group label.active:hover {
	background-color: #2980b9;
}


.flight-booking-page .switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	margin-right: 10px;
}

.flight-booking-page .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.flight-booking-page .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: .4s;
	border-radius: 34px;
}

.flight-booking-page .slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	transition: .4s;
	border-radius: 50%;
}

.flight-booking-page input:checked + .slider {
	background: linear-gradient(45deg, #6C5CE7, #00B894);
}

.flight-booking-page input:checked + .slider:before {
	left: 14px;
	transform: translateX(26px);
}

.flight-booking-page .toggle-label {
	font-size: 14px;
	font-weight: 600;
	margin-right: 30%;
	color: #2D3436;
}

@media (max-width: 1024px) {
	.flight-booking-page .flight-booking {
		flex-direction: column;
	}

	.flight-booking-page .world-map {
		height: 300px;
		margin-left: 0;
		margin-bottom: 20px;
	}

	.flight-booking-page .booking-panel {
		flex: none;
		width: 100%;
		max-width: 600px;
		margin: 0 auto;
		max-height: none;
	}
}

@media (max-width: 768px) {
	.flight-booking-page .flight-booking {
		padding: 10px;
	}

	.flight-booking-page .booking-panel {
		padding: 20px;
	}

	.flight-booking-page .booking-title {
		font-size: 24px;
	}

	.flight-booking-page .form-row {
		flex-direction: column;
		gap: 15px;
	}

	.flight-booking-page select,
	.flight-booking-page input {
		font-size: 14px;
		padding: 12px 15px;
	}

	.flight-booking-page .submit-btn {
		padding: 15px;
		font-size: 16px;
	}
}

@media (max-width: 480px) {
	.flight-booking-page .world-map {
		height: 200px;
	}

	.flight-booking-page .booking-title {
		font-size: 20px;
	}

	.flight-booking-page .booking-form {
		gap: 15px;
	}

	.flight-booking-page .form-group label {
		font-size: 10px;
	}

	.flight-booking-page select,
	.flight-booking-page input {
		font-size: 12px;
		padding: 10px 12px;
	}

	.flight-booking-page .submit-btn {
		padding: 12px;
		font-size: 14px;
	}
}

.form-row.passenger-count {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 20px;
  }
  
  .form-row.passenger-count .form-group {
	flex: 1;
  }
  
  .form-row.passenger-count .form-group label {
	display: block;
	margin-bottom: 5px;
  }
  
  .form-row.passenger-count .form-group input {
	width: 87%;
	padding: 13px;
	font-size: 16px;
  }