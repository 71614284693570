.terms-of-use {
  font-family: 'Heebo', sans-serif;
  color: #333;
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.terms-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('https://img.freepik.com/free-photo/landscape-sky-dusk-beauty-beach_1203-6292.jpg?size=626&ext=jpg&ga=GA1.1.891334498.1682224155&semt=ais_hybrid');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  padding: 4rem 2rem;
}

.terms-header h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.terms-header p {
  font-size: 1.2rem;
  font-weight: 300;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.terms-content {
  max-width: 800px;
  margin: 4rem auto;
  padding: 0 2rem;
  flex-grow: 1;
}

.terms-content section {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
}

.section-icon {
  font-size: 2rem;
  color: #6a63c9;
  margin-right: 1.5rem;
  flex-shrink: 0;
}

.section-text {
  flex-grow: 1;
}

.terms-content h2 {
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.terms-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

.terms-footer {
  background-color: #1a1a1a;
  color: #fff;
  text-align: center;
  padding: 2rem;
  margin-top: auto;
}

.terms-footer p {
  margin: 0.5rem 0;
}

@media (max-width: 768px) {
  .terms-header h1 {
    font-size: 2.5rem;
  }

  .terms-header p {
    font-size: 1rem;
  }

  .terms-content section {
    flex-direction: column;
  }

  .section-icon {
    margin-bottom: 1rem;
  }
}