.order-history-container {
  max-width: 800px;
  margin: 0px auto; /* הוספת מרווח עליון ותחתון */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-history-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
}

.orders-table {
  width: 100%;
  border-collapse: separate; /* משנה את התנהגות הטבלה */
  border-spacing: 0 10px; /* מוסיף מרווח בין השורות */
}

.orders-table th, .orders-table td {
  padding: 15px; /* מגדיל את הרווח בתאים */
  text-align: left;
  background-color: #fff; /* רקע לבן לכל תא */
  border: none; /* מבטל את גבול התא */
}

.orders-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  border-bottom: 2px solid #ddd; /* מוסיף גבול תחתון עבה יותר לכותרות */
}

.orders-table td ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0; /* מסיר את המרווחים ברשימה */
}

.orders-table td ul li {
  margin: 5px 0;
}

.orders-table tr {
  margin-bottom: 10px; /* מרווח בין השורות */
}

.loading {
  text-align: center;
  font-size: 24px;
  color: #666;
  padding: 50px 0;
}
