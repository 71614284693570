.faq-page {
  font-family: 'Heebo', sans-serif;
  color: #333;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.faq-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 2rem;
}

.faq-hero h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.faq-hero p {
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 600px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.faq-content {
  max-width: 800px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.faq-item {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.faq-question {
  font-family: 'Playfair Display', serif;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.faq-question:hover {
  background-color: rgba(201, 167, 99, 0.1);
}

.faq-question.active {
  background-color: #6385c9;
  color: #fff;
}

.faq-icon {
  transition: transform 0.3s ease;
}

.faq-icon.active {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 0 1.5rem 1.5rem;
  line-height: 1.6;
  color: #666;
}

@media (max-width: 768px) {
  .faq-hero h1 {
    font-size: 2.5rem;
  }

  .faq-hero p {
    font-size: 1rem;
  }

  .faq-question {
    font-size: 1rem;
  }
}