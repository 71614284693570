@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@700&display=swap');

.not-found-container {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #87CEEB, #1E90FF);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.not-found-content {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 3rem;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.not-found-title {
  font-family: 'Playfair Display', serif;
  font-size: 6rem;
  color: #1E90FF;
  margin: 0;
  line-height: 1;
}

.not-found-subtitle {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  color: #333;
  margin: 0.5rem 0 1.5rem;
}

.not-found-message {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.not-found-animation {
  position: relative;
  height: 150px;
  margin: 2rem 0;
}

.not-found-container .plane-container {
  position: absolute;
  left: 0;
  top: 50%;
  color: #1E90FF;
}

.not-found-container .cloud {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  opacity: 0.8;
}

.not-found-container .cloud1 {
  width: 100px;
  height: 40px;
  left: 20%;
  top: 30%;
}

.not-found-container .cloud2 {
  width: 80px;
  height: 30px;
  right: 25%;
  top: 50%;
}

.not-found-container .cloud3 {
  width: 60px;
  height: 25px;
  left: 40%;
  bottom: 20%;
}

.not-found-container .cloud::before,
.not-found-container .cloud::after {
  content: '';
  position: absolute;
  background-color: white;
  border-radius: 50%;
}

.not-found-container .cloud::before {
  width: 50%;
  height: 100%;
  top: -50%;
  left: 10%;
}

.not-found-container .cloud::after {
  width: 30%;
  height: 100%;
  top: -30%;
  right: 10%;
}

.not-found-container .not-found-help {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 1.5rem;
}

.not-found-container .not-found-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.not-found-container .not-found-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #1E90FF;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.not-found-container .not-found-button:hover {
  background-color: #1873CC;
}

@media (max-width: 768px) {
  .not-found-content {
    padding: 2rem;
  }

  .not-found-title {
    font-size: 4rem;
  }

  .not-found-subtitle {
    font-size: 2rem;
  }

  .not-found-actions {
    flex-direction: column;
  }
}