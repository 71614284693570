@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.payment-links-container {
  --primary-color: #2c3e50;
  --secondary-color: #3498db;
  --background-color: #ecf0f1;
  --text-color: #34495e;
  --border-color: #bdc3c7;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --success-color: #27ae60;
  --danger-color: #e74c3c;

  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.payment-links-container__title {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.payment-links-container__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.payment-links-container__add-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-links-container__add-button:hover {
  background-color: #2980b9;
}

.payment-links-container__search {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.payment-links-container__search input {
  border: none;
  padding: 0.5rem;
  font-size: 1rem;
  width: 200px;
}

.payment-links-container__search input:focus {
  outline: none;
}

.payment-links-container__form {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--shadow-color);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-links-container__form input,
.payment-links-container__edit-input {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.payment-links-container__form input:focus,
.payment-links-container__edit-input:focus {
  outline: none;
  border-color: var(--secondary-color);
}

.payment-links-container__submit-button {
  background-color: var(--success-color);
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-links-container__submit-button:hover {
  background-color: #219a52;
}

.payment-links-container__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-links-container__item {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px var(--shadow-color);
  transition: transform 0.3s ease;
}

.payment-links-container__item:hover {
  transform: translateY(-2px);
}

.payment-links-container__item-description {
  margin: 0 0 0.5rem 0;
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 500;
}

.payment-links-container__item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.payment-links-container__item-amount {
  margin: 0;
  font-weight: 500;
  color: var(--text-color);
}

.payment-links-container__pay-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--secondary-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.payment-links-container__pay-button:hover {
  background-color: #2980b9;
}

.payment-links-container__actions {
  display: flex;
  gap: 0.5rem;
}

.payment-links-container__action-button {
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-links-container__action-button--edit {
  background-color: #f1c40f;
}

.payment-links-container__action-button--delete {
  background-color: var(--danger-color);
  color: white;
}

.payment-links-container__action-button--edit:hover {
  background-color: #bd9e22;
}

.payment-links-container__action-button--delete:hover {
  background-color: #c43020;
  color: white;
}


.payment-links-container__action-button--save {
  background-color: var(--success-color);
  color: white;
}

.payment-links-container__action-button--save:hover {
  background-color: #219a52;
}

.payment-links-container__action-button--cancel {
  background-color: var(--danger-color);
  color: white;
}

.payment-links-container__action-button--cancel:hover {
  background-color: #c0392b;
}

.payment-links-container__edit-row {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.payment-links-container__edit-input--amount {
  width: 100px;
}

.payment-links-container__edit-input--url {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .payment-links-container {
    padding: 1rem;
  }

  .payment-links-container__controls {
    flex-direction: column;
    gap: 1rem;
  }

  .payment-links-container__search {
    width: 100%;
  }

  .payment-links-container__search input {
    width: 100%;
  }

  .payment-links-container__item-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .payment-links-container__actions {
    margin-top: 0.5rem;
  }
}