/* .progress-tracker-page {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #f6f9fc 0%, #e9f2f9 100%);
  min-height: 40vh;
  margin-bottom: -10vh;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.progress-tracker-page .progress-tracker-container {
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.progress-tracker-page .progress-tracker {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.progress-tracker-page .progress-tracker::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  background-color: #e0e0e0;
  z-index: 1;
}

.progress-tracker-page .step {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 6);
}

.progress-tracker-page .step-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #bdbdbd;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.progress-tracker-page .step.active .step-icon {
  background-color: #0047AB;
  color: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 8px 25px rgba(0, 71, 171, 0.3);
}

.progress-tracker-page .step-label {
  font-size: 14px;
  font-weight: 600;
  color: #757575;
  text-align: center;
  transition: all 0.3s ease;
}

.progress-tracker-page .step.active .step-label {
  color: #0047AB;
}

.progress-tracker-page .step-line {
  position: absolute;
  top: 40px;
  left: 50%;
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  z-index: -1;
}

.progress-tracker-page .step.active .step-line {
  background-color: #0047AB;
}


.progress-tracker-page .step.active.last-step .step-icon {
  background-color: #2ecc71; 
  box-shadow: 0 8px 25px rgba(46, 204, 113, 0.3);
}

.progress-tracker-page .step.active.last-step .step-label {
  color: #2ecc71; 
}

.progress-tracker-page .step.active.last-step .step-line {
  background-color: #2ecc71; 
}


html[dir="rtl"] .progress-tracker-page .step-line {
  right: 50%;
  left: auto;
}

html[dir="rtl"] .progress-tracker-page .step.active .step-line {
  background-color: #0047AB;
}

html[dir="rtl"] .progress-tracker-page .ic-he {
  transform: scaleX(-1); 
}


@media (max-width: 768px) {
  .progress-tracker-page .progress-tracker {
    flex-direction: column;
    align-items: flex-start;
  }

  .progress-tracker-page .step {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .progress-tracker-page .step-icon {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .progress-tracker-page .progress-tracker::before {
    display: none;
  }

  .progress-tracker-page .step-line {
    top: 40px;
    left: 40px;
    width: 4px;
    height: calc(100% + 20px);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 71, 171, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 71, 171, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 71, 171, 0);
  }
}

.progress-tracker-page .step.active .step-icon {
  animation: pulse 2s infinite;
} */









.progress-tracker-page {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #f6f9fc 0%, #e9f2f9 100%);
  min-height: 40vh;
  margin-bottom: -10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.progress-tracker-page .progress-tracker-container {
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.progress-tracker-page .progress-tracker {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.progress-tracker-page .progress-tracker::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  background-color: #e0e0e0;
  z-index: 1;
}

.progress-tracker-page .step {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 6);
}

.progress-tracker-page .step-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #bdbdbd;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.progress-tracker-page .step.active .step-icon {
  background-color: #0047AB;
  color: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 8px 25px rgba(0, 71, 171, 0.3);
}

.progress-tracker-page .step-label {
  font-size: 14px;
  font-weight: 600;
  color: #757575;
  text-align: center;
  transition: all 0.3s ease;
}

.progress-tracker-page .step.active .step-label {
  color: #0047AB;
}

.progress-tracker-page .step-line {
  position: absolute;
  top: 40px;
  left: 50%;
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  z-index: -1;
}

.progress-tracker-page .step.active .step-line {
  background-color: #0047AB;
}

.progress-tracker-page .step.active.last-step .step-icon {
  background-color: #2ecc71;
  box-shadow: 0 8px 25px rgba(46, 204, 113, 0.3);
}

.progress-tracker-page .step.active.last-step .step-label {
  color: #2ecc71;
}

.progress-tracker-page .step.active.last-step .step-line {
  background-color: #2ecc71;
}

html[dir="rtl"] .progress-tracker-page .step-line {
  right: 50%;
  left: auto;
}

html[dir="rtl"] .progress-tracker-page .step.active .step-line {
  background-color: #0047AB;
}

html[dir="rtl"] .progress-tracker-page .ic-he {
  transform: scaleX(-1);
}

@media (max-width: 768px) {
  .progress-tracker-page {
    min-height: auto;
    margin-bottom: 0;
    padding: 10px;
  }

  .progress-tracker-page .progress-tracker-container {
    padding: 15px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .progress-tracker-page .progress-tracker {
    width: max-content;
    min-width: 100%;
    padding-bottom: 10px;
  }

  .progress-tracker-page .step {
    width: auto;
    min-width: 80px;
    margin-right: 10px;
  }

  .progress-tracker-page .step-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 5px;
  }

  .progress-tracker-page .step-label {
    font-size: 12px;
  }

  .progress-tracker-page .step-line {
    top: 20px;
  }

  .progress-tracker-page .progress-tracker::before {
    top: 20px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 71, 171, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 71, 171, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 71, 171, 0);
  }
}

.progress-tracker-page .step.active .step-icon {
  animation: pulse 2s infinite;
}