.add-flight-container {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --success-color: #2ecc71;
  --danger-color: #e74c3c;
  --background-color: #f8f9fa;
  --text-color: #333;
  --border-color: #ced4da;

  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  max-width: 1000px;
  width: 90%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-flight-container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.add-flight-container .add-flight-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.add-flight-container .form-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  text-align: center;
  font-weight: 300;
}

.add-flight-container .form-section {
  background-color: #f1f3f5;
  padding: 1.5rem;
  border-radius: 8px;
}

.add-flight-container .section-title {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

.add-flight-container .flight-type-toggle.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.add-flight-container .flight-type-toggle .form-group {
  flex: 1;
  margin-right: 1rem;
}

.add-flight-container .flight-type-toggle .form-group:last-child {
  margin-right: 0;
}

.add-flight-container .flight-type-toggle label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-color);
}

.add-flight-container .flight-type-toggle .radio-group {
  display: flex;
  align-items: center;
  background-color: #f1f3f5;
  border-radius: 4px;
  padding: 0.5rem;
}

.add-flight-container .flight-type-toggle .radio-group input[type="radio"] {
  margin-right: 0.5rem;
}

.add-flight-container .flight-type-toggle .radio-group label {
  margin-bottom: 0;
  font-size: 1rem;
  color: var(--text-color);
  cursor: pointer;
}

.add-flight-container .flight-type-toggle .radio-group input[type="radio"]:checked + label {
  font-weight: 600;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .add-flight-container .flight-type-toggle.form-row {
    flex-direction: column;
  }

  .add-flight-container .flight-type-toggle .form-group {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .add-flight-container .flight-type-toggle .form-group:last-child {
    margin-bottom: 0;
  }
}

.add-flight-container .input-group {
  margin-bottom: 1.5rem;
}

.add-flight-container .input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-color);
}

.add-flight-container .input-group input,
.add-flight-container .input-group select {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: #ffffff;
}

.add-flight-container .input-group input:focus,
.add-flight-container .input-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.25);
}

.add-flight-container .origin-destination {
  display: flex;
  gap: 1rem;
}

.add-flight-container .origin,
.add-flight-container .destination {
  flex: 1;
}

.add-flight-container .date-time-group {
  display: flex;
  gap: 1rem;
}

.add-flight-container .date-input,
.add-flight-container .time-input {
  flex: 1;
}

.add-flight-container .class-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.add-flight-container .submit-btn {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.add-flight-container .submit-btn:hover {
  background-color: #2980b9;
}

.add-flight-container .countries-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.add-flight-container .country-item {
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.add-flight-container .delete-country {
  background: none;
  border: none;
  color: var(--danger-color);
  cursor: pointer;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  padding: 0;
  display: flex;
  align-items: center;
}

.add-flight-container .delete-country:hover,
.add-flight-container .delete-country:focus {
  color: #c0392b;
}

.add-flight-container .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 768px) {
  .add-flight-container {
    width: 95%;
    padding: 1rem;
  }

  .add-flight-container .form-title {
    font-size: 2rem;
  }

  .add-flight-container .origin-destination,
  .add-flight-container .date-time-group {
    flex-direction: column;
  }

  .add-flight-container .class-details {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .add-flight-container .form-title {
    font-size: 1.75rem;
  }

  .add-flight-container .section-title {
    font-size: 1.25rem;
  }

  .add-flight-container .submit-btn {
    width: 100%;
  }
}


.add-flight-container .flight-type-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.add-flight-container .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.add-flight-container .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.add-flight-container .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.add-flight-container .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.add-flight-container input:checked + .slider {
  background: linear-gradient(45deg, #6C5CE7, #00B894);
}

.add-flight-container input:checked + .slider:before {
  left: 14px;
  transform: translateX(26px);
}

.add-flight-container .toggle-label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 30%;
  color: #2D3436;
}

.add-flight-container .submit-btn.loading {
  background-color: var(--text-color);
  cursor: not-allowed;
}

