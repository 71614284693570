.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.floating-button .chat-button {
  background-color: #0066cc;
  color: white;
  border-radius: 30%;
  width: 60px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.floating-button .chat-button:hover {
  background-color: #005bb5;
  transform: scale(1.05);
}

.floating-button .close-button {
  position: absolute;
  top: -10px; 
  right: -10px; 
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.floating-button .close-button:hover {
  background-color: #e04444;
}