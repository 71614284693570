.settings-page {
    padding: 20px;
}

.language-buttons {
    display: flex;
    gap: 10px;
}

.language-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: 1 px ;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.language-btn:hover {
    background-color: #0056b3;
}

.language-btn.active {
    background-color: #28a745; /* Green background for active language */
    color: white;
}
