.pleasuair-gallery.image-gallery,
.pleasuair-partners.partner-section,
.pleasuair-offices.office-section {
  padding: 6rem 0;
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
}

.gallery-page .title {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  color: #1a1a1a;
}

.gallery-page .gallery-grid,
.gallery-page .partner-grid,
.gallery-page .office-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
}

.gallery-page .gallery-item,
.gallery-page .partner-item,
.gallery-page .office-item {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-page .gallery-item:hover,
.gallery-page .partner-item:hover,
.gallery-page .office-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.gallery-page .gallery-image,
.gallery-page .office-image {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.gallery-page .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-page .gallery-item:hover .image-overlay {
  opacity: 1;
}

.gallery-page .image-overlay p {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  padding: 0 1rem;
}

.gallery-page .modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.gallery-page .modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.gallery-page .close-button {
  position: absolute;
  top: -2rem;
  right: -2rem;
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.gallery-page .modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.gallery-page .partner-item {
  background-color: #ffffff;
  padding: 2rem;
  text-align: center;
}

.gallery-page .partner-logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 1.5rem;
}

.gallery-page .partner-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.gallery-page .partner-description {
  font-size: 1rem;
  color: #666;
}

.gallery-page .office-info {
  padding: 1.5rem;
  background-color: #ffffff;
}

.gallery-page .office-city {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.gallery-page .office-address {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .gallery-page .title {
    font-size: 2.5rem;
  }

  .gallery-page .gallery-grid,
  .gallery-page .partner-grid,
  .gallery-page .office-grid {
    grid-template-columns: 1fr;
  }
}