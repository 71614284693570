.payment-container {
  min-height: 100vh;
  background: linear-gradient(to bottom right, #e0e7ff, #c7d2fe);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  font-family: Arial, sans-serif;
}

.payment-container .toggle-summary-button {
  background-color: #4a5568;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.payment-container .toggle-summary-button:hover {
  background-color: #2d3748;
}


@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.payment-container .booking-summary {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 30px;
  max-width: 800px;
  width: 100%;
}

.payment-container .summary-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.payment-container .summary-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.payment-container .summary-label {
  font-weight: 600;
  color: #34495e;
  margin-bottom: 4px;
}

.payment-container .summary-value {
  color: #2c3e50;
}

.payment-container .total-price {
  grid-column: 1 / -1;
  background: linear-gradient(to right, #3498db, #2980b9);
  color: white;
}

.payment-container .total-price .summary-label,
.payment-container .total-price .summary-value {
  color: white;
  font-weight: 700;
}

.payment-container .summary-item.total-price {
  grid-column: 1 / -1;
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.payment-container .total-price .summary-label,
.payment-container .total-price .summary-value {
  color: white;
  font-weight: 700;
}

.payment-container .price-breakdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment-container .breakdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.payment-container .breakdown-list li {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
}

.payment-container .total-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  border-radius: 8px;
}

.payment-container .total-price .summary-value {
  font-size: 1.4em;
}

@media (max-width: 768px) {
  .payment-container .summary-item.total-price {
    flex-direction: column;
  }

  .payment-container .total-cost {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}

.payment-container .total-price .summary-value {
  font-size: 1.2em;
}

.payment-container .payment-card {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .payment-container .payment-card {
    flex-direction: row;
  }
}

.payment-container .card-info {
  padding: 2rem;
  background: linear-gradient(to bottom right, #7c3aed, #4f46e5);
  color: white;
}

.payment-container .card-info h2 {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.payment-container .card-info p {
  margin-bottom: 1.5rem;
}

.payment-container .credit-card-widget {
  perspective: 1000px;
  width: 100%;
  max-width: 400px;
  height: 225px;
  margin: 0 auto;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.payment-container .credit-card-widget.flipped {
  transform: rotateY(180deg);
}

.payment-container .credit-card-front,
.payment-container .credit-card-back {
  direction: ltr !important;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background: linear-gradient(to bottom right, #1f2937, #111827);
}

.payment-container .credit-card-back {
  transform: rotateY(180deg);
}

.payment-container .card-chip {
  width: 50px;
  height: 40px;
  background: linear-gradient(to bottom right, #fbbf24, #d97706);
  border-radius: 8px;
  margin-bottom: 2rem;
}

.payment-container .card-number,
.payment-container .card-name,
.payment-container .card-expiry {
  font-family: monospace;
  color: white;
  margin-bottom: 0.5rem;
}

.payment-container .card-number {
  font-size: 1.25rem;
}

.payment-container #card-number {
  direction: ltr !important;
}

.payment-container .card-stripe {
  background: #374151;
  height: 40px;
  margin: 1rem 0;
}

.payment-container .card-cvv {
  background: white;
  color: black;
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 4px;
}

.payment-container .payment-form {
  padding: 2rem;
}



.payment-container .form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.payment-container .security-note {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.payment-container .card-icon {
  margin-right: 0.5rem;
}

.payment-container .save-order-button {
  width: 40%;
  padding: 0.75rem;
  background: linear-gradient(to right, #10b981, #059669);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
}

.payment-container .save-order-button:hover {
  background: linear-gradient(to right, #0caa76, #057c56);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.payment-container .save-order-button.saved {
  background: linear-gradient(to right, #ef4444, #dc2626);
}

.payment-container .save-order-button.saved:hover {
  background: linear-gradient(to right, #dc2626, #b91c1c);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}

.payment-container .payment-status {
  position: fixed;
  top: 80px;
  right: 20px;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: bold;
  z-index: 100;
}

.payment-container .payment-status.success {
  background-color: #10b981;
  color: white;
}

.payment-container .payment-status.failed {
  background-color: #ef4444;
  color: white;
}

.payment-container .toast {
  position: fixed;
  top: 20px;
  left: 20%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation: fadeInOut 3s ease-in-out;
  z-index: 1000;
  text-align: center;
  min-width: 200px;
}

.payment-container .toast.success {
  background-color: #4CAF50;
}

.payment-container .toast.error {
  background-color: #F44336;
  margin-bottom: 30px;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.payment-container .insurance-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.payment-container .insurance-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment-container .insurance-label input {
  margin-right: 0.5rem;
}

.payment-container .insurance-info {
  position: relative;
  display: inline-block;
}

.payment-container .info-icon {
  color: #4f46e5;
  cursor: pointer;
}

.payment-container .info-text {
  display: none;
  position: absolute;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.75rem;
  width: 250px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 10;
  font-size: 0.875rem;
  color: #4b5563;
}

.payment-container .insurance-info:hover .info-text {
  display: block;
}

.payment-container .total-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
}

.payment-container .cost-value {
  color: #0f941c;
}

.payment-container .form-group.terms-acceptance {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.payment-container .terms-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment-container .terms-label input {
  margin-right: 0.5rem;
}

.payment-container .terms-link {
  color: #4f46e5;
  text-decoration: underline;
  cursor: pointer;
}

.payment-container .terms-link:hover {
  color: #4338ca;
}

.payment-container .waiver-button {
  width: 37%;
  margin-right: 10%;
  padding: 0.5rem;
  background: linear-gradient(to right, #f59e0b, #d97706);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 1rem;
}

.payment-container .waiver-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.payment-container .waiver-button.confirmed {
  background: linear-gradient(to right, #ef4444, #dc2626);
}

.payment-container .waiver-button.confirmed:hover {
  transform: none;
  box-shadow: none;

}

.payment-container .pay-button:disabled {
  background: #9ca3af;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.swal2-popup {
  font-size: 1rem;
}

.swal2-popup .swal2-title {
  font-size: 1.5rem;
  color: #2c3e50;
}

.swal2-popup .swal2-html-container {
  text-align: left;
}

.swal2-popup .swal2-confirm {
  background: linear-gradient(to right, #7c3aed, #4f46e5) !important;
}

.swal2-popup .swal2-cancel {
  background: #9ca3af !important;
}

.payment-container .toast {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation: fadeInOut 3s ease-in-out;
  z-index: 1000;
}

.payment-container .toast.success {
  background-color: #10b981;
  color: white;
}

.payment-container .toast.error {
  background-color: #ef4444;
  color: white;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}


.payment-container .payment-method-selection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f3f4f6;
  border-radius: 0.5rem;
}

.payment-container .payment-method-selection label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment-container .payment-method-selection input[type="radio"] {
  margin-right: 0.5rem;
}

.payment-container .coupon-section {
  display: flex;
  flex-direction: row;
  align-items: center; /* Align vertically */
  gap: 0.5rem; /* Space between button and input */
}

.payment-container .apply-coupon-btn {
  padding: 0.5rem 1rem;
  background: linear-gradient(to right, #10b981, #059669);
  color: white;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 0.5rem;
}

.payment-container .apply-coupon-btn:hover {
  background: linear-gradient(to right, #059669, #047857);
}


.payment-container .error-message {
  color: #ef4444;
  font-size: 0.875rem;
}








.payment-container .payment-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.payment-container .radio-group {
  display: flex;
  gap: 1rem;
}

.payment-container .radio-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.payment-container .form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.payment-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.payment-container .form-group label {
  font-weight: 500;
  color: #4b5563;
}

.payment-container .form-group input {
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.payment-container .checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.payment-container .insurance-info {
  margin-left: 1.5rem;
}

.payment-container .form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 450px;
}


.payment-container .submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #4f46e5;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.payment-container .FaCreditCard {
  margin-bottom: -2px;
}

.payment-container .submit-button:hover {
  background-color: #4338ca;
}

.payment-container .submit-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}


@media (max-width: 640px) {
  .payment-container .form-grid {
    grid-template-columns: 1fr;
  }

  .payment-container .form-footer {
    flex-direction: column;
    gap: 1rem;
  }
}

.payment-container #cash-announcement {
  margin: 0%;
}

.credit-card-commission-note {
  margin: 0%;
  margin-top: -8%;
}













.payment-container .card-info {
  padding: 2rem;
  background: linear-gradient(to bottom right, #7c3aed, #4f46e5);
  color: white;
  position: relative;
}

.payment-container .payment-widget {
  perspective: 1000px;
  width: 100%;
  max-width: 400px;
  height: 225px;
  margin: 0 auto;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.payment-container .payment-widget.flipped {
  transform: rotateY(180deg);
}

.payment-container .credit-card-front,
.payment-container .credit-card-back,
.payment-container .coupon-display {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background: linear-gradient(to bottom right, #1f2937, #111827);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-container .credit-card-back {
  transform: rotateY(180deg);
}

.payment-container .coupon-display {
  background: linear-gradient(to bottom right, #10b981, #059669);
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.payment-container .payment-widget.coupon-mode .credit-card-front,
.payment-container .payment-widget.coupon-mode .credit-card-back {
  opacity: 0;
}

.payment-container .payment-widget.coupon-mode .coupon-display {
  opacity: 1;
}

.payment-container .card-chip {
  width: 50px;
  height: 40px;
  background: linear-gradient(to bottom right, #fbbf24, #d97706);
  border-radius: 8px;
}

.payment-container .card-number,
.payment-container .card-name,
.payment-container .card-expiry {
  font-family: monospace;
  color: white;
}

.payment-container .card-number {
  font-size: 1.25rem;
  letter-spacing: 2px;
}

.payment-container .card-stripe {
  background: #374151;
  height: 40px;
  margin: 1rem 0;
}

.payment-container .card-cvv {
  background: white;
  color: black;
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 4px;
}

.payment-container .coupon-code {
  text-align: center;
  word-break: break-all;
}