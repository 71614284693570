.manage-users-container {
  font-family: 'Roboto', sans-serif;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.manage-users-container h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 300;
}

.manage-users-container .search-container {
  position: relative;
  margin-bottom: 20px;
  width: 95%;
}

.manage-users-container .search-input {
  width: 100%;
  padding: 10px 15px 10px 40px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.manage-users-container .search-input:focus {
  border-color: #4a90e2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(74, 144, 226, 0.25);
}

.manage-users-container .search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.manage-users-container .table-container {
  overflow-x: auto;
}

.manage-users-container .users-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.manage-users-container .users-table th,
.manage-users-container .users-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #e9ecef;
}

.manage-users-container .users-table th {
  background-color: #4a90e2;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.manage-users-container .users-table th:hover {
  background-color: #3a7bc8;
}

.manage-users-container .users-table tr:last-child td {
  border-bottom: none;
}

.manage-users-container .users-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.manage-users-container .sort-icon {
  margin-left: 5px;
  font-size: 0.8em;
}

.manage-users-container .dropdown {
  position: relative;
  display: inline-block;
}

.manage-users-container .dropbtn {
  background-color: #4a90e2;
  color: #fff;
  padding: 8px 16px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.manage-users-container .dropbtn:hover,
.manage-users-container .dropbtn:focus {
  background-color: #3a7bc8;
}

.manage-users-container .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: -30;
  margin-left: -60%;
}


.manage-users-container .dropdown:hover .dropdown-content {
  display: flex;
}

.manage-users-container .dropdown-content button {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.manage-users-container .dropdown-content button:hover {
  background-color: #f1f3f5;
}

.manage-users-container .dropdown-content button.delete:hover {
  background-color: #ffebee;
}

.manage-users-container .icon {
  margin-right: 8px;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .manage-users-container {
    padding: 15px;
  }

  .manage-users-container .users-table th,
  .manage-users-container .users-table td {
    padding: 10px;
  }

  .manage-users-container .dropbtn {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .manage-users-container h1 {
    font-size: 2rem;
  }

  .manage-users-container .users-table th,
  .manage-users-container .users-table td {
    padding: 8px;
  }

  .manage-users-container .dropbtn {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
}

.manage-users-container .action-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  padding: 5px;
  font-size: 1rem;
  color: #4a90e2;
  transition: color 0.3s ease;
}

.manage-users-container .action-button:hover {
  color: #3a7bc8;
}

.manage-users-container .action-button.delete {
  color: #e74c3c;
}

.manage-users-container .action-button.delete:hover {
  color: #c0392b;
}

.manage-users-container .action-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.5);
}

.manage-users-container .action-button-container {
  position: relative;
  display: inline-block;
}

.manage-users-container .action-button {
  position: relative;
}

.manage-users-container .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
  transition: opacity 0.3s;
}

.manage-users-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -35px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.manage-users-container .action-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}