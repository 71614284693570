@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.admin-page {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease;
}

.admin-page .admin-page-sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px 0;
    position: fixed;
    top: 68px;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease;
    overflow-y: auto;
    z-index: 1000;
}

.admin-page.collapsed .admin-page-sidebar {
    width: 60px;
}

.admin-page .sidebar-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #ecf0f1;
}

.admin-page .admin-page-sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-top: 40px;
}

.admin-page .admin-page-sidebar li {
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
}

.admin-page .admin-page-sidebar li:hover, 
.admin-page .admin-page-sidebar li.active {
    background-color: #34495e;
}

.admin-page .admin-page-sidebar .icon {
    font-size: 20px;
    margin-right: 15px;
}

.admin-page.collapsed .admin-page-sidebar .label {
    display: none;
}

.admin-page .main-content {
    flex: 1;
    padding: 40px;
    margin-left: 250px;
    transition: margin-left 0.3s ease;
}

.admin-page.collapsed .main-content {
    margin-left: 60px;
}

.admin-page .main-content h1 {
    margin-bottom: 30px;
    color: #2c3e50;
    font-size: 32px;
}

.mobile-menu-toggle {
    display: none;
    position: fixed;
    top: 80px;
    left: 10px;
    z-index: 1001;
    background-color: #2c3e50;
    color: #ecf0f1;
    border: none;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
}

/* Login page styles */
.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
}

.login-page h2 {
    margin-bottom: 20px;
    color: #2c3e50;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.login-page input {
    padding: 15px;
    font-size: 18px;
    margin-bottom: 20px;
    width: 350px;
    border: 2px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease;
}

.login-page input:focus {
    border-color: #007bff;
    outline: none;
}

.login-page button {
    padding: 12px 30px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
}

.login-page button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.login-page button:active {
    transform: translateY(1px);
}

.login-page button:focus {
    outline: none;
}

/* Responsive styles */
@media (max-width: 768px) {
    .mobile-menu-toggle {
        display: block;
    }

    .admin-page .admin-page-sidebar {
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        width: 100%;
        top: 0;
        padding-top: 60px;
    }

    .admin-page.mobile-menu-open .admin-page-sidebar {
        transform: translateX(0);
    }

    .admin-page .main-content {
        margin-left: 0;
        padding: 20px;
    }

    .admin-page.collapsed .main-content {
        margin-left: 0;
    }

    .admin-page .sidebar-toggle {
        display: none;
    }

    .admin-page .admin-page-sidebar li {
        padding: 20px;
    }

    .admin-page .admin-page-sidebar .label {
        display: inline-block !important;
    }

    .admin-page .main-content h1 {
        font-size: 24px;
    }

    .login-page input {
        width: 90%;
        max-width: 350px;
    }
}

/* RTL Support */
html[dir="rtl"] .admin-page .admin-page-sidebar {
    left: auto;
    right: 0;
}

html[dir="rtl"] .admin-page .sidebar-toggle {
    right: auto;
    left: 10px;
}

html[dir="rtl"] .admin-page .admin-page-sidebar .icon {
    margin-right: 0;
    margin-left: 15px;
}

html[dir="rtl"] .admin-page .main-content {
    margin-left: 0;
    margin-right: 250px;
}

html[dir="rtl"] .admin-page.collapsed .main-content {
    margin-right: 60px;
}

@media (max-width: 768px) {
    html[dir="rtl"] .mobile-menu-toggle {
        left: auto;
        right: 10px;
    }

    html[dir="rtl"] .admin-page .admin-page-sidebar {
        transform: translateX(100%);
    }

    html[dir="rtl"] .admin-page.mobile-menu-open .admin-page-sidebar {
        transform: translateX(0);
    }

    html[dir="rtl"] .admin-page .main-content {
        margin-right: 0;
    }

    html[dir="rtl"] .admin-page.collapsed .main-content {
        margin-right: 0;
    }
}

/* Additional styles for better mobile experience */
@media (max-width: 480px) {
    .admin-page .main-content {
        padding: 15px;
    }

    .admin-page .main-content h1 {
        font-size: 22px;
    }

    .admin-page .admin-page-sidebar li {
        padding: 15px;
    }

    .login-page h2 {
        font-size: 24px;
    }

    .login-page input,
    .login-page button {
        font-size: 16px;
    }
}

/* Improve accessibility */
.admin-page .admin-page-sidebar li:focus,
.admin-page .sidebar-toggle:focus,
.mobile-menu-toggle:focus {
    outline: 2px solid #007bff;
    outline-offset: 2px;
}

/* Smooth transitions for better UX */
.admin-page .admin-page-sidebar,
.admin-page .main-content,
.admin-page .admin-page-sidebar li,
.login-page input,
.login-page button {
    transition: all 0.3s ease;
}

/* Ensure content is never hidden behind the sidebar on very small screens */
@media (max-height: 500px) {
    .admin-page .admin-page-sidebar {
        position: absolute;
    }
}