.flight-details-page {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f7f9;
  min-height: 100vh;
  padding: 40px 20px;
}

.flight-details-page .flight-details-container {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.flight-details-page .flight-header {
  background: linear-gradient(135deg, #0047AB, #6AB1E5);
  color: #ffffff;
  padding: 30px;
  text-align: center;
}

.flight-details-page .flight-header h1 {
  margin: 0;
  font-size: 2.5rem;
  margin-bottom: 10px;
}


.flight-details-page .flight-route {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flight-details-page .flight-route .plane-icon {
  margin: 0 15px;
  font-size: 1.5rem;
}

html[dir="rtl"] .flight-details-page .flight-route .plane-icon {
  transform: scaleX(-1);
}

.flight-details-page .flight-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 30px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;

}



.flight-details-page .info-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: flex-end; 
}

.flight-details-page .info-item svg {
  margin-left: 10px; 
  margin-right: 10px;
  color: #0047AB;
  font-size: 1.2rem;
}


html[dir="rtl"] .flight-details-page .info-item svg {
  margin-left: 10;
  margin-right: 10px; 
}

.flight-details-page .flight-classes {
  padding: 30px;
}

.flight-info-container{
  padding: 30px;

}

.flight-details-page .flight-classes h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.flight-details-page .class-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.flight-details-page .class-item {
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.flight-details-page .class-item h3 {
  margin-top: 0;
  color: #0047AB;
  border-bottom: 2px solid #0047AB;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.flight-details-page .class-item p {
  margin: 5px 0;
}

.flight-details-page .continue-btn {
  display: block;
  width: calc(100% - 60px);
  margin: 30px auto;
  padding: 15px;
  background-color: #0047AB;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.flight-details-page .continue-btn:hover {
  background-color: #003380;
}

@media (max-width: 768px) {
  .flight-details-page .flight-info {
    grid-template-columns: 1fr 1fr;
  }
  
  .flight-details-page .class-details {
    grid-template-columns: 1fr;
  }
}