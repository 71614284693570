.flight-tickets-page {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #f6f9fc 0%, #e9f2f9 100%);
  min-height: 100vh;
  padding: 40px 20px;
}

.flight-tickets-page .main-content {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.flight-tickets-page .flight-header {
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: #ffffff;
  padding: 30px;
  text-align: center;
}

.flight-tickets-page .flight-header h1 {
  margin: 0 0 15px;
  font-size: 2.5rem;
}

.flight-tickets-page .flight-route {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.flight-tickets-page .flight-route svg {
  margin: 0 15px;
}

.flight-tickets-page .flight-date {
  font-size: 1rem;
  opacity: 0.8;
}

/* .flight-tickets-page .flight-summary {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.flight-tickets-page .flight-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flight-tickets-page .info-column {
  flex: 1;
  min-width: 200px;
  margin-bottom: 15px;
}

.flight-tickets-page .info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.flight-tickets-page .info-item svg {
  font-size: 24px;
  color: #3498db;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 3px;
}

.flight-tickets-page .info-item div {
  display: flex;
  flex-direction: column;
}

.flight-tickets-page .info-label {
  font-size: 12px;
  color: #6c757d;
  text-transform: uppercase;
  margin-bottom: 2px;
} */

.flight-tickets-page .flight-summary {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.flight-tickets-page .flight-info {
  margin-bottom: 20px;
}

.flight-tickets-page .flight-info h3 {
  font-size: 1.2rem;
  color: #3498db;
  margin-bottom: 15px;
}

.flight-tickets-page .info-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flight-tickets-page .info-column {
  flex: 1;
  min-width: 200px;
  margin-bottom: 15px;
}

.flight-tickets-page .info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.flight-tickets-page .info-item svg {
  font-size: 24px;
  color: #3498db;
  margin-right: 15px;
  margin-top: 3px;
}

.flight-tickets-page .info-item div {
  display: flex;
  flex-direction: column;
}

.flight-tickets-page .info-label {
  font-size: 12px;
  color: #6c757d;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.flight-tickets-page .info-item span:not(.info-label) {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 500;
}

@media (max-width: 768px) {
  .flight-tickets-page .info-columns {
    flex-direction: column;
  }

  .flight-tickets-page .info-column {
    margin-bottom: 20px;
  }
}

.flight-tickets-page .info-item span:not(.info-label) {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 500;
}

.flight-tickets-page .passenger-selection {
  padding: 20px;
}

.flight-tickets-page .passenger-selection h2 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.flight-tickets-page .class-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.flight-tickets-page .class-card {
  background-color: #f8f9fa;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.flight-tickets-page .class-card:hover,
.flight-tickets-page .class-card.selected {
  border-color: #3498db;
  box-shadow: 0 5px 15px rgba(52, 152, 219, 0.2);
}

.flight-tickets-page .class-card h3 {
  margin-top: 0;
  color: #2c3e50;
}

.flight-tickets-page .class-card .price {
  font-size: 1.5rem;
  color: #e74c3c;
  font-weight: bold;
  margin: 10px 0;
}

.flight-tickets-page .ticket-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.flight-tickets-page .ticket-input label {
  margin-right: 10px;
  margin-left: 10px;
}

.flight-tickets-page .ticket-input input {
  width: 60px;
  padding: 5px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
}

.flight-tickets-page .passenger-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
}

.flight-tickets-page .form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.flight-tickets-page .form-group:last-child {
  margin-right: 0;
}

.flight-tickets-page .form-group label {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}

.flight-tickets-page .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  font-size: 16px;
}

.flight-tickets-page .form-group input[type="number"] {
  width: 60px;
  text-align: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.flight-tickets-page .form-group input[type="number"]::-webkit-inner-spin-button,
.flight-tickets-page .form-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flight-tickets-page .form-group input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  .flight-tickets-page .passenger-inputs {
    flex-direction: column;
  }

  .flight-tickets-page .form-group {
    width: 100%;
    margin-bottom: 10px;
  }

  .flight-tickets-page .form-group:last-child {
    margin-bottom: 0;
  }
}

.flight-tickets-page .booking-footer {
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flight-tickets-page .total-summary {
  display: flex;
  flex-direction: column;
}

.flight-tickets-page .total-tickets,
.flight-tickets-page .total-price {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.flight-tickets-page .total-price .price {
  font-weight: bold;
  color: #e74c3c;
  margin-left: 10px;
}

.flight-tickets-page .book-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #2ecc71;
  color: white;
}

.flight-tickets-page .book-btn:hover {
  opacity: 0.9;
}

.flight-tickets-page .book-btn:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.flight-tickets-page .passenger-count {
  margin-top: 10px;
}

.flight-tickets-page .passenger-count p {
  margin: 5px 0;
  font-size: 14px;
  color: #2c3e50;
}

html[dir="rtl"] .flight-tickets-page .f-t-plane-icon {
  transform: scaleX(-1);
}

@media (max-width: 768px) {
  .flight-tickets-page .flight-info,
  .flight-tickets-page .booking-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .flight-tickets-page .info-item,
  .flight-tickets-page .booking-footer > * {
    margin-bottom: 10px;
  }

  .flight-tickets-page .passenger-inputs {
    flex-direction: column;
  }

  .flight-tickets-page .form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.flight-tickets-page .class-card.selected {
  border: 2px solid #3498db;
  background-color: #f0f8ff;
}