
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.account-page-content .account-page {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease;
    margin-top: 3%;
}

.account-page-content .sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px 0;
    position: fixed;
    top: 68px;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease;
    overflow-y: auto;
    z-index: 1000;
}

.account-page-content .account-page.collapsed .sidebar {
    width: 60px;
}

.account-page-content .sidebar-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #ecf0f1;
}

.account-page-content .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-top: 40px;
}

.account-page-content .sidebar li {
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
}

.account-page-content .sidebar li:hover, 
.account-page-content .sidebar li.active {
    background-color: #34495e;
}

.account-page-content .sidebar .icon {
    font-size: 20px;
    margin-right: 15px;
}

.account-page-content .account-page.collapsed .sidebar .label {
    display: none;
}

.account-page-content .main-content {
    flex: 1;
    padding: 40px;
    margin-left: 250px;
    transition: margin-left 0.3s ease;
}

.account-page-content .account-page.collapsed .main-content {
    margin-left: 60px;
}

.account-page-content .main-content h1 {
    margin-bottom: 30px;
    color: #2c3e50;
    font-size: 32px;
}

.account-page-content .account-section {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.account-page-content .account-section h2 {
    margin-bottom: 20px;
    color: #2c3e50;
    font-size: 24px;
}

.account-page-content .account-section .input-group {
    margin-bottom: 20px;
}

.account-page-content .account-section .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #7f8c8d;
}

.account-page-content .account-section .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 16px;
}

.account-page-content .primary-btn, 
.account-page-content .secondary-btn, 
.account-page-content .danger-btn {
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.account-page-content .primary-btn {
    background-color: #3498db;
    color: #fff;
}

.account-page-content .primary-btn:hover {
    background-color: #2980b9;
}

.account-page-content .secondary-btn {
    background-color: #95a5a6;
    color: #fff;
}

.account-page-content .secondary-btn:hover {
    background-color: #7f8c8d;
}

.account-page-content .danger-btn {
    background-color: #e74c3c;
    color: #fff;
}

.account-page-content .danger-btn:hover {
    background-color: #c0392b;
}

.account-page-content .account-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.account-page-content .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* RTL Support */
html[dir="rtl"] .account-page-content .sidebar {
    left: auto;
    right: 0;
}

html[dir="rtl"] .account-page-content .sidebar-toggle {
    right: auto;
    left: 10px;
}

html[dir="rtl"] .account-page-content .sidebar .icon {
    margin-right: 0;
    margin-left: 15px;
}

html[dir="rtl"] .account-page-content .main-content {
    margin-left: 0;
    margin-right: 250px;
}

html[dir="rtl"] .account-page-content .account-page.collapsed .main-content {
    margin-right: 60px;
}

.mobile-menu-toggle {
    display: none;
    position: fixed;
    top: 80px;
    left: 10px;
    z-index: 1001;
    background-color: #2c3e50;
    color: #ecf0f1;
    border: none;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .mobile-menu-toggle {
        display: block;
    }

    .account-page-content .sidebar {
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        width: 100%;
        top: 0;
        padding-top: 60px;
    }

    .account-page-content .account-page.mobile-menu-open .sidebar {
        transform: translateX(0);
    }

    .account-page-content .main-content {
        margin-left: 0;
        padding: 20px;
    }

    .account-page-content .account-page.collapsed .main-content {
        margin-left: 0;
    }

    .account-page-content .sidebar-toggle {
        display: none;
    }

    .account-page-content .sidebar li {
        padding: 20px;
    }

    .account-page-content .sidebar .label {
        display: inline-block !important;
    }

    html[dir="rtl"] .account-page-content .mobile-menu-toggle {
        left: auto;
        right: 10px;
    }

    html[dir="rtl"] .account-page-content .sidebar {
        transform: translateX(100%);
    }

    html[dir="rtl"] .account-page-content .account-page.mobile-menu-open .sidebar {
        transform: translateX(0);
    }

    html[dir="rtl"] .account-page-content .main-content {
        margin-right: 0;
    }

    .account-page-content .main-content h1 {
        font-size: 24px;
    }

    .account-page-content .account-section {
        padding: 20px;
    }

    .account-page-content .account-section h2 {
        font-size: 20px;
    }

    .account-page-content .primary-btn, 
    .account-page-content .secondary-btn, 
    .account-page-content .danger-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .account-page-content .account-actions {
        flex-direction: column;
    }
}