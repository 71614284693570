/* About Us Container */
.about-us-container {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  background-color: #f8f8f8;
  line-height: 1.6;
}

/* About Us Hero Section */
.about-us-container .about-us-hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  color: #fff; /* Main heading and subheading are white */
  position: relative;
}

/* Feature Images Styling */
.about-us-container .feature-item img {
  width: 100%;
  height: 200px; /* גובה קבוע לכל התמונות */
  object-fit: cover; /* כדי שהתמונה תתאים למסגרת מבלי להתעוות */
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Background Overlay for Hero Section */
.about-us-container .about-us-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

/* Main Heading Styling */
.about-us-container .about-us-hero h1 {
  font-family: 'Playfair Display', serif;
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: #fff; /* White color for main heading */
}

/* Subheading Styling */
.about-us-container .about-us-hero p {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 800px;
  position: relative;
  z-index: 1;
  color: #fff; /* White color for subheading */
}

/* Specific Section Styling for About Us Page */
.about-us-container .about-us-section {
  padding: 100px 10%;
  margin-bottom: 50px;
}

/* About Us Section Headings */
.about-us-container h2 {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: #1a1a1a;
  margin-bottom: 30px;
  text-align: center;
}

.about-us-container h3 {
  color: #000; /* Black color for other headings */
}

/* About Us Section Paragraphs */
.about-us-container p {
  font-size: 1.1rem;
  color: #4a4a4a;
  margin-bottom: 20px;
}

/* About Us Founder Section */
.about-us-container .about-us-founder {
  display: flex;
  align-items: center;
  gap: 50px;
}

.about-us-container .founder-image {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-us-container .founder-text {
  width: 60%;
}

/* Blockquote Styling */
.about-us-container blockquote {
  font-style: italic;
  font-size: 1.2rem;
  color: #1a1a1a;
  border-left: 5px solid #c9a763;
  padding-left: 20px;
  margin-bottom: 20px;
}

/* Grid Styling */
.about-us-container .feature-grid, .about-us-container .values-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

/* Feature & Value Items */
.about-us-container .feature-item, .about-us-container .value-item {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-us-container .feature-item:hover, .about-us-container .value-item:hover {
  transform: translateY(-10px);
}

/* Feature & Value Images */
.about-us-container .feature-item img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Feature & Value Headings */
.about-us-container .feature-item h3, .about-us-container .value-item h3 {
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  color: #000; /* Black color for other headings */
  margin-bottom: 15px;
}

/* About Us Staff Section */
.about-us-container .about-us-staff {
  display: flex;
  align-items: center;
  gap: 50px;
}

.about-us-container .staff-image {
  width: 50%;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.about-us-container .staff-text {
  width: 50%;
}

/* Awards Image Styling */
.about-us-container .awards-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 30px;
}

/* Achievements List Styling */
.about-us-container .achievements-list {
  list-style-type: none;
  padding: 0;
}

.about-us-container .achievements-list li {
  font-size: 1.1rem;
  color: #4a4a4a;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

.about-us-container .achievements-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #c9a763;
  font-weight: bold;
}

/* Future Section Styling */
.about-us-container .about-us-future {
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 10px;
  padding: 50px;
}

.about-us-container .about-us-future h2 {
  color: #fff;
}

.about-us-container .about-us-future ul {
  list-style-type: none;
  padding: 0;
}

.about-us-container .about-us-future li {
  font-size: 1.1rem;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

.about-us-container .about-us-future li::before {
  content: '→';
  position: absolute;
  left: 0;
  color: #c9a763;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .about-us-container .about-us-founder, .about-us-container .about-us-staff {
    flex-direction: column;
  }

  .about-us-container .founder-image, .about-us-container .founder-text, .about-us-container .staff-image, .about-us-container .staff-text {
    width: 100%;
  }

  .about-us-container .feature-grid, .about-us-container .values-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .about-us-container .about-us-hero h1 {
    font-size: 3rem;
  }

  .about-us-container .about-us-hero p {
    font-size: 1.2rem;
  }

  .about-us-container .about-us-section {
    padding: 50px 5%;
  }

  .about-us-container h2 {
    font-size: 2.5rem;
  }

  .about-us-container .feature-grid, .about-us-container .values-grid {
    grid-template-columns: 1fr;
  }
}
