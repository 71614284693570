

  .boarding-pass {
    direction: ltr;
    display: flex;
    flex-direction: row;
    width: 800px;
    max-width: 1200px;
    height: auto;
    background-color: #f0f8ff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    margin-bottom: 20px;
}

.boarding-pass .logo-image {
    width: 150px; 
}

.boarding-pass .main-ticket {
    flex: 3;
    padding: 20px;
    position: relative;
    background-color: #ffffff;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect width="100" height="100" fill="none"/><path d="M0 0L100 100M100 0L0 100" stroke="rgba(200,200,200,0.2)" stroke-width="0.5"/></svg>');
    background-repeat: repeat;
}

.boarding-pass .tear-off {
    flex: 1;
    padding: 20px;
    border-left: 2px dashed #1e90ff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.boarding-pass .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: #1e90ff 1px solid;
}

.boarding-pass .header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.boarding-pass .airline {
    font-size: 20px;
    font-weight: bold;
    color: #1e90ff;
}

.boarding-pass .title {
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
}

.boarding-pass .travel-class {
    font-size: 18px;
    color: #1e90ff;
}

.boarding-pass .content {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 20px;
}

.boarding-pass label {
    display: block;
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
}

.boarding-pass span {
    font-size: 16px;
    font-weight: bold;
}

.boarding-pass .route-info {
    margin: 0%;
    grid-column: 1 / -1; 
    display: flex;
    justify-content: space-between; 
    align-items: center;
}

.boarding-pass .arrow {
  /* margin-top: -35px; */
  font-size: 24px;
  color: #1e90ff;
}

.boarding-pass .qr-code {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.boarding-pass .tear-off .qr-code {
    position: static;
    align-self: center;
}

.boarding-pass .tear-off .airline {
  font-size: 18px;
}

.boarding-pass .tear-off .flight-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boarding-pass .tear-off .route-info,
.boarding-pass .tear-off .seat-time,
.boarding-pass .tear-off .passenger-info {
  display: flex;
  justify-content: space-between;
}

.boarding-pass .tear-off label {
    font-size: 10px;
}

.boarding-pass .tear-off span {
    font-size: 14px;
}

@media (max-width: 800px) {
    .boarding-pass {
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .boarding-pass .main-ticket, .tear-off {
        width: 100%;
    }

    .boarding-pass .tear-off {
        border-left: none;
        border-top: 2px dashed #1e90ff;
    }
}



