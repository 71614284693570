/* .saved-flights-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  font-size: 24px;
  color: #0066cc;
  margin-bottom: 20px;
  text-align: center;
}

.no-flights {
  font-size: 18px;
  color: #666;
  text-align: center;
}

.flights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.flight-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
}

.flight-card:hover {
  transform: translateY(-5px);
}

.flight-name {
  font-size: 20px;
  color: #0066cc;
  margin-bottom: 15px;
}

.flight-details p {
  margin: 10px 0;
  color: #333;
}

.details-btn, .remove-btn {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.remove-btn {
  background-color: #dc3545;
}

.details-btn:hover {
  background-color: #0052a3;
}

.remove-btn:hover {
  background-color: #c82333;
} */








.saved-flights-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  background-color: #f0f8ff; 
}

.saved-flights-container .section-title {
  text-align: center;
  color: #003366; 
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.saved-flights-container .section-title::after {
  font-size: 1.5rem;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.saved-flights-container .no-flights {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-top: 2rem;
}

.saved-flights-container .flights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.saved-flights-container .flight-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.saved-flights-container .flight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.saved-flights-container .flight-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, #003366, #0066cc);
}

.saved-flights-container .flight-name {
  background-color: #003366;
  color: #ffffff;
  padding: 1rem;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
}

.saved-flights-container .flight-details {
  padding: 1rem;
}

.saved-flights-container .flight-details p {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  color: #333;
}

.saved-flights-container .flight-details p strong {
  min-width: 60px;
  color: #0066cc;
}

.saved-flights-container .flight-details p::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.saved-flights-container .flight-details p:nth-child(1)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230066cc"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>');
}

.saved-flights-container .flight-details p:nth-child(2)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230066cc"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>');
}

.saved-flights-container .flight-details p:nth-child(3)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230066cc"><path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/></svg>');
}

.saved-flights-container .flight-details p:nth-child(4)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230066cc"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/></svg>');
}

.saved-flights-container.details-btn,
.saved-flights-container.remove-btn {
  width: 100%;
  padding: 0.8rem;
  border: none;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.saved-flights-container .details-btn {
  background-color: #0066cc;
}

.saved-flights-container .details-btn:hover {
  background-color: #004c99;
}

.saved-flights-container .remove-btn {
  background-color: #cc0000;
}

.saved-flights-container .remove-btn:hover {
  background-color: #990000;
}

@media (max-width: 768px) {
  .saved-flights-container .flights-grid {
    grid-template-columns: 1fr;
  }
}

/* Animation for new flights */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.saved-flights-container .flight-card {
  animation: fadeIn 0.5s ease-out;
}