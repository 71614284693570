.flights-page {
  font-family: 'Roboto', sans-serif;
  background-color: #F0F8FF;
  color: #333;
  line-height: 1.6;
}

.flights-page .flights-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.flights-page .section-title {
  font-size: 2.5rem;
  color: #0047AB;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.flights-page .section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(to right, #0047AB, #00BFFF);
}

.flights-page .flights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem; 
}

.flights-page .flight-card {
  background-color: #FFFFFF;
  border-radius: 12px; 
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.75rem; 
  max-width: 420px;
}

.flights-page .flight-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to right, #0047AB, #00BFFF);
}

.flights-page .flight-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.flights-page .flight-name {
  background: #0047AB;
  color: #FFFFFF;
  padding: 0.75rem; 
  font-size: 1.3rem; 
  margin: -0.75rem -0.75rem 0.75rem -0.75rem; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flights-page .flight-name::after {
  content: '✈️';
  font-size: 1.8rem;
}

.flights-page .flight-details {
  flex-grow: 1;
}

.flights-page .flight-details p {
  display: flex;
  align-items: center;
  margin: 0.4rem 0; 
  padding-bottom: 0.4rem; 
  border-bottom: 1px solid #eee;
  font-size: 0.9rem; 
}

.flights-page .flight-details p:last-child {
  border-bottom: none;
}

.flights-page .flight-details strong {
  width: 70px; 
  color: #0047AB;
  font-size: 0.9rem; 
}

.flights-page .button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.flights-page .details-btn,
.flights-page .save-btn,
.flights-page .details-btn1,
.flights-page .save-btn1 {
  width: 48%;

  padding: 0.75rem;
  border: none;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px;
}

.flights-page .details-btn1 {
  width: 66%;
}

.flights-page .save-btn1 {
  width: 31%;

}


.flights-page .details-btn,
.flights-page .details-btn1 {
  background-color: #0047AB;
  color: #FFFFFF;
}

.flights-page .details-btn:hover,
.flights-page .details-btn1:hover {
  background-color: #003366;
}

.flights-page .save-btn,
.flights-page .save-btn1 {
  background-color: #00BFFF;
  color: #FFFFFF;
}

.flights-page .save-btn:hover,
.flights-page .save-btn1:hover {
  background-color: #0099CC;
}

.flights-page .remove-btn,
.flights-page .remove-btn1 {
  background-color: #FF4500;
}

.flights-page .remove-btn:hover,
.flights-page .remove-btn1:hover {
  background-color: #d15f4b;
}

.flights-page .no-flights {
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  margin: 2rem 0;
  padding: 2rem;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}


.flights-page .progress-tracker {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding: 1.5rem;
  background-color: #FFFFFF;
  border-radius: 50px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.flights-page .progress-step {
  display: flex;
  align-items: center;
  position: relative;
}

.flights-page .progress-step::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #DDD;
}

.flights-page .progress-step:last-child::after {
  display: none;
}

.flights-page .step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #DDD;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 1rem;
  transition: all 0.3s ease;
}

.flights-page .step-number.active {
  background-color: #0047AB;
  box-shadow: 0 0 0 5px rgba(0, 71, 171, 0.2);
}

.flights-page .step-label {
  color: #999;
  font-weight: 500;
  transition: all 0.3s ease;
}

.flights-page .step-label.active {
  color: #0047AB;
}

.flights-page .flight-details p::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.flights-page .flight-details p:nth-child(2)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230066cc"><path d="M2.5 19h19v2h-19v-2zm19.57-9.36c-.21-.8-1.04-1.28-1.84-1.06L14.92 10l-6.9-6.43-1.93.51 4.14 7.17-4.97 1.33-1.97-1.54-1.45.39 1.82 3.16.77 1.33 1.6-.43 5.31-1.42 4.35-1.16L21 11.49c.81-.23 1.28-1.05 1.07-1.85z"/></svg>');
}

.flights-page .flight-details p:nth-child(3)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="%230066cc"><path d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"/></svg>');
}

.flights-page .flight-details p:nth-child(4)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230066cc"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/></svg>');
}

.flights-page .flight-details p:nth-child(5)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230066cc"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/></svg>');
}

html[dir="rtl"] .flights-page .flight-details p:nth-child(2)::before, 
html[dir="rtl"] .flights-page .flight-details p:nth-child(3)::before {
  transform: scaleX(-1);
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .flights-page .flights-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  }
  
  .flights-page .progress-tracker {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .flights-page .progress-step {
    margin-bottom: 1rem;
  }
  
  .flights-page .progress-step::after {
    display: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.flights-page .flight-card {
  animation: fadeInUp 0.6s ease-out;
  animation-fill-mode: both;
}

.flights-page .flight-card:nth-child(even) {
  animation-delay: 0.2s;
}





@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flights-page .search-again-btn {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  margin: 2rem auto;
  padding: 1rem 2rem;
  background-color: #FFD700;
  color: #0047AB;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem; /* Increase font size for better readability */
  font-weight: bold;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 250px; /* Increased width to fit all content */
  text-transform: none !important;
}

.flights-page .search-again-btn:hover {
  background-color: #FFC700;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.flights-page .search-again-btn .icon {
  margin-right: 20px; /* Increased space between icon and text */
  margin-top: 2%;
  font-size: 24px; /* Increased icon size */
  position: absolute;
  transition: transform 0.3s ease, opacity 0.3s ease; /* For animation effects */
}

.flights-page .search-again-btn .refresh-icon {
  left: 20px; /* Adjust position of refresh icon */
  opacity: 1; /* Visible by default */
}

.flights-page .search-again-btn .search-icon {
  left: 20px; /* Adjust position of search icon */
  opacity: 0; /* Hidden by default */
  transform: scale(0.8); /* Start smaller */
}

.flights-page .search-again-btn:hover .refresh-icon {
  animation: spin 0.8s ease-in-out; /* Spin the refresh icon */
  opacity: 0; /* Fade out refresh icon */
  transform: scale(0.8); /* Shrink the refresh icon */
  transition-delay: 0.3s; /* Delay fade out until after spin */
}

.flights-page .search-again-btn:hover .search-icon {
  opacity: 1; /* Fade in search icon */
  transform: scale(1); /* Return to normal size */
  transition-delay: 0.5s; /* Delay fade in until after spin */
}













.flights-page .flight-card.round-trip {
  grid-column: span 2; /* Make round-trip cards span two columns */
}

.flights-page .flight-details-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  /* max-width: 20px; */
}

.flights-page .flight-details {
  flex: 1;
}

.flights-page .flight-details h4 {
  color: #0047AB;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .flights-page .flight-card.round-trip {
    grid-column: span 1; /* On smaller screens, make round-trip cards take full width */
  }

  .flights-page .flight-details-container {
    flex-direction: column;
  }
}