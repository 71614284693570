.chat-container {
  direction: ltr !important;
  font-family: 'Heebo', sans-serif;
  color: #333;
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
}

.chat-container .chat-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 4rem 2rem;
  text-align: center;
  color: #fff;
}

.chat-container .chat-header h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.chat-container .chat-header p {
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 600px;
  margin: 0 auto;
}

.chat-container .chat-box {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  background-color: #fff;
  border-radius: 15px;
  height: 60vh;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-container .chat-messages {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow-y: auto;
  max-height: 400px;
}

.chat-container .initial-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.chat-container .initial-message .agent-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1rem;
  object-fit: cover;
}

.chat-container .initial-message p {
  font-size: 1.2rem;
  color: #666;
  max-width: 80%;
}

.chat-container .message {
  max-width: 70%;
  padding: 1rem 2rem;
  margin-bottom: 0.5rem;
  border-radius: 20px;
}

.chat-container .message.user {
  align-self: flex-end;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  text-align: right;
  border-bottom-right-radius: 4px;
}

.chat-container .message.agent {
  align-self: flex-start;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  color: #333;
  text-align: left;
  border-bottom-left-radius: 4px;
}

.chat-container .chat-input {
  display: flex;
  padding: 1.5rem;
  background-color: #f9f9f9;
}

.chat-container .chat-input input {
  flex-grow: 1;
  padding: 0.8rem 1.5rem;
  border: 2px solid  #3854c1;
  border-radius: 30px;
  font-size: 1rem;
  outline: none;
}

.chat-container .chat-input button {
  background-color: #3854c1;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.chat-container .chat-input button:hover {
  background-color: #2525ce;
}

.chat-container .typing-indicator {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.chat-container .typing-indicator span {
  height: 10px;
  width: 10px;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
  animation: typing 1s infinite alternate;
}

.chat-container .typing-indicator span:nth-of-type(2) {
  animation-delay: 0.33s;
}

.chat-container .typing-indicator span:nth-of-type(3) {
  animation-delay: 0.66s;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@media (max-width: 768px) {
  .chat-container .chat-header h1 {
    font-size: 2.5rem;
  }

  .chat-container .chat-header p {
    font-size: 1rem;
  }

  .chat-container .chat-box {
    margin: 1rem;
  }

  .chat-container .chat-messages {
    max-height: 300px;
  }

  .chat-container .initial-message .agent-image {
    width: 150px;
    height: 150px;
  }

  .chat-container .initial-message p {
    font-size: 1rem;
  }
}

